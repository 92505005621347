<template>
  <div class="page-post-type-index">
    <PostDataWrapper postType="page" :slug="pageSlug">
      <template v-slot="slotProps">
        <Page :page="slotProps.post">
          <template v-slot="pageProps">
            <Banner :image-versions="pageProps.featuredImageVersions">
              <h1>{{ pageProps.title }}</h1>
              <p>{{ pageProps.subHeading }}</p>
            </Banner>

            <PageContainer>
              <div class="column main-content">
                <PostsDataWrapper
                  :postType="postType"
                  :postTypePlural="postTypePlural"
                  :params="pagination"
                  @loaded="postsLoaded"
                >
                  <template v-slot:default="slotProps">
                    <PostPreviewList
                      :posts="slotProps.posts"
                      :postRouteName="postRouteName"
                      buttonText="Learn more"
                      :buttonUnderlined="true"
                    />
                  </template>
                </PostsDataWrapper>

                <Pagination v-model="pagination" />
              </div>

              <Sidebar
                class="column"
                :advice="pageProps.relatedAdvice"
                :services="pageProps.relatedServices"
                :packages="pageProps.relatedPackages"
              />
            </PageContainer>
          </template>
        </Page>
      </template>
    </PostDataWrapper>
  </div>
</template>

<script>
import pagination from '@/mixins/pagination'
import page from '@/mixins/page'

import Banner from '@/components/Banner'
import PageContainer from '@/components/PageContainer'
import Pagination from '@/components/Pagination'
import PostsDataWrapper from '@/components/PostsDataWrapper'
import PostPreviewList from '@/components/PostPreviewList'
import Sidebar from '@/components/Sidebar'

export default {
  name: 'PostTypeIndex',

  mixins: [page, pagination],

  components: {
    Banner,
    PageContainer,
    Pagination,
    PostsDataWrapper,
    PostPreviewList,
    Sidebar
  },

  computed: {
    meta() {
      return this.$route.meta
    },

    pageSlug() {
      return this.meta.pageSlug
    },

    postType() {
      return this.meta.postType
    },

    postTypePlural() {
      return this.meta.postTypePlural
    },

    postRouteName() {
      return this.meta.postRouteName
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.page-post-type-index
  .post-preview-list
    flex-direction: column

  ::v-deep .post-preview
    padding: 0 0 $pad*2
    flex-direction: row

    @include media($media-gt-big)
      padding-bottom: $pad*4

    .left
      .lazy-img
        img
          max-height: 350px

        @include media($media-gt-big)
          max-height: 220px


      @include media($media-gt-big)
        max-width: 240px

    .column
      padding: $pad 0
      @include row

      @include media($media-gt-big)
        padding: 0 $pad
        @include column

  ::v-deep .contained
    @include media($media-gt-big)
      display: flex

    > .column
      padding: $pad 0
      @include row

      @include media($media-gt-big)
        padding: 0 $pad

    .main-content
      @include media($media-gt-big)
        padding-left: 0
        padding-right: $pad*4
        flex: 2.5
</style>
