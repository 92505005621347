<template>
  <Container class="testimonials">
    <ul class="testimonials-list">
      <Testimonial
        v-for="testimonial in testimonials"
        :key="testimonial.id"
        :testimonial="testimonial"
      />
    </ul>
  </Container>
</template>

<script>
import Container from '@/components/Container'
import Testimonial from './Testimonial'

export default {
  name: 'Testimonials',

  props: {
    testimonials: {
      type: Array,
      required: true
    }
  },

  components: {
    Container,
    Testimonial
  }
}
</script>

<style lang="sass" scoped>
@import "../../stylesheets/base/variables"
@import "../../stylesheets/helpers/mixins"

.testimonials
  ::v-deep ,container .contained
    padding: $pad*4 $pad
    border-top: 1px solid $color-underlines

    @include media($media-gt-big)
      padding: $pad*4 $pad

  .testimonials-list
    @include list
    width: 100%

    @include media($media-gt-big)
      display: flex
      justify-content: space-between
      align-items: center
</style>
