<template>
  <div class="select" :class="classList">
    <select
      v-model="resource"
      :class="classList"
      :id="_uid"
      @focus="onFocus"
      @blur="onBlur"
    >
      <slot />
    </select>

    <label class="label" :for="_uid">{{ placeholder }}</label>
    <Error :error="error" />
  </div>
</template>

<script>
import input from '@/mixins/input'

import Error from '../Error'

export default {
  name: 'Select',

  mixins: [input],

  props: {
    placeholder: {
      type: String,
      required: true
    },

    error: {
      type: String,
      required: false
    },

    helperText: {
      type: String,
      required: false
    }
  },

  components: {
    Error
  },

  data() {
    return {
      hasFocus: false
    }
  },

  computed: {
    hasValue() {
      return !!this.value
    },

    classList() {
      return {
        'has-focus': this.hasFocus,
        'has-value': this.hasValue,
        'is-invalid': !!this.error
      }
    }
  },

  methods: {
    onFocus() {
      this.hasFocus = true
    },

    onBlur() {
      this.hasFocus = false
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../../stylesheets/base/variables"
@import "../../../stylesheets/helpers/mixins"

%focus
  outline: none

  + label
    bottom: 50px
    color: $color-dark
    font-size: 12px

.select
  position: relative
  border: none

  label
    pointer-events: none
    display: block
    position: absolute
    font-family: $font-main
    font-size: 16px
    color: lighten($color-dark, 25)
    bottom: 22px
    transition: bottom .2s ease, color .2s ease, font-size .2s ease
    cursor: text

  select
    font-size: 16px
    font-family: $font-main
    border: none
    display: block
    width: 100%
    border-radius: 0
    border-bottom: 1px solid $color-dark
    padding: 2px 0px
    background: $color-light

    @include media($media-gt-big)
      display: inline-block

    &:focus
      @extend %focus

  option
    padding: 0

  &.has-value
    select
      @extend %focus

  &.is-invalid.has-focus
    select
      + label
        color: $color-error
</style>
