<template>
  <div v-if="hasServices" class="related-services">
    <h3>{{ title }} services</h3>

    <ul class="related-services-list">
      <PostDataWrapper
        v-for="article in services"
        :key="article.ID"
        postType="service"
        postTypePlural="services"
        :slug="article.post_name"
      >
        <template v-slot:default="slotProps">
          <ServicesItem :post="slotProps.post" />
        </template>
      </PostDataWrapper>
    </ul>
  </div>
</template>

<script>
import ServicesItem from './ServicesItem'
import PostDataWrapper from '@/components/PostDataWrapper'

export default {
  name: 'RelatedServices',

  props: {
    services: {
      type: Array,
      required: true
    },

    title: {
      required: false,
      default() {
        return 'Related'
      }
    }
  },

  components: {
    PostDataWrapper,
    ServicesItem
  },

  computed: {
    hasServices() {
      return this.services.length
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../../stylesheets/base/variables"
@import "../../../stylesheets/helpers/mixins"

.related-services
  h3
    font-size: 24px
    padding-bottom: $pad
    border-bottom: 2px solid $color-underlines
    margin: 0

  .related-services-list
    @include list
</style>
