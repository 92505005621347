/**
 * numeric html chars to utf8
 */
export function decodeHtml(value) {
  return value.replace(/&#([0-9]{1,3});/gi, function(match, numStr) {
    let num = parseInt(numStr, 10)
    return String.fromCharCode(num)
  })
}

/**
 * capitalise first letter of a word
 */
export function capitalise(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
