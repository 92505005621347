<template>
  <nav class="navigation" :class="{'is-active': active}">
    <ul class="navigation-list">
      <li
        v-for="item in mappedItems"
        :key="item.name"
        class="navigation-list-item"
        :class="item.name.toLowerCase()"
      >
        <router-link
          :to="item.target"
          @click.native="close"
          :title="item.title"
          exact
        >
          {{ item.name }}
        </router-link>

        <ul v-if="item.children" class="sub-menu-list">
          <li
            v-for="child in item.children"
            :key="child.name"
            class="sub-menu-list-item"
          >
            <router-link
              :class="child.name.toLowerCase()"
              :to="child.target"
              @click.native="close"
              :title="child.title"
            >
              {{ child.name }}
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
import dataWrapper from '@/mixins/data_wrapper'

import {mapActions, mapState} from 'vuex'

const MAIN_MENU_SLUG = 'main-menu'

export default {
  name: 'Navigation',

  mixins: [dataWrapper],

  props: {
    active: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState(['menusBySlug']),

    menu() {
      return this.menusBySlug[MAIN_MENU_SLUG] || {}
    },

    items() {
      return this.menu.items || []
    },

    mappedItems() {
      return this.items.map((item) => {
        const name = item.title
        const title = item.title
        const target = {name: item.acf.route_name}
        const children = false

        return {
          name,
          title,
          target,
          children
        }
      })
    },

    shouldGetMenu() {
      return this.resourceStale(this.resourceReadAt(this.menu))
    }
  },

  methods: {
    ...mapActions(['getMenu']),

    close() {
      this.$emit('close')
    },

    getData() {
      const slug = MAIN_MENU_SLUG
      return this.getMenu({slug})
    }
  },

  serverPrefetch() {
    return this.getData()
  },

  mounted() {
    if (this.shouldGetMenu) {
      this.getData()
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../stylesheets/base/variables"
@import "../../stylesheets/helpers/mixins"

.navigation
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 900
  transform: translateX(100%)
  transition: opacity, transform
  transition-duration: $transition-duration
  transition-easing: $transition-easing
  opacity: 0
  background: $color-light
  display: flex
  align-items: center
  text-transform: uppercase
  font-size: 12px
  letter-spacing: 1.2px

  &.is-active
    transform: translateX(0%)
    opacity: 1

  @include media($media-gt-big)
    background: transparent
    position: relative
    transform: translateX(0%)
    opacity: 1
    border-top: 1px solid $color-underlines
    padding: $pad 0
    transition: padding $transition-duration $transition-easing

    .is-scrolling &
      transition-easing: $transition-easing
      padding: $pad / 2 0

    .home
      display: none

  .close
    @include media($media-gt-big)
      display: none

  ul
    list-style: none
    display: inline-block
    width: 100%
    padding: 0
    margin: 0
    position: relative

    @include media($media-gt-big)
      display: flex
      justify-content: space-between
      align-items: center

  li
    font-family: $font-main
    font-weight: bold
    text-align: center
    padding: $pad / 2
    position: relative

    @include media($media-gt-big)
      display: inline-block
      padding: 0 $pad

      &:nth-of-type(2)
        padding-left: 0

      &:last-of-type
        padding-right: 0

    a
      text-decoration: none
      color: $color-dark
      transition: color border-color
      transition-duration: $transition-duration
      transtion-easing: $transition-easing
      border-bottom: 2px solid
      border-color: transparent

      &:hover
        color: $color-highlight
        border-color: $color-highlight

      &.router-link-active
        color: $color-highlight

  .sub-menu-list
    a
      color: $color-dark

    @include media($media-gt-big)
      position: absolute
      top: 100%
      left: 0
      min-width: 120px
      padding: $pad / 4
      display: none

  .sub-menu-list-item
    padding: $pad / 4
    display: block

    &:last-of-type
      padding-right: $pad / 4

  .navigation-list-item
    @include media($media-gt-big)
      &:hover
        > .sub-menu-list
          display: inline-block
</style>
