import Vue from 'vue'
import App from './App.vue'
import {sync} from 'vuex-router-sync'

import '@/stylesheets/main.sass'

import {createRouter} from './router'
import {createStore} from './store'

Vue.config.productionTip = false

export function createApp(context) {
  const router = createRouter()
  const store = createStore(context)

  store.state.hostName = context.hostName

  sync(store, router)

  /* eslint-disable no-new */
  const app = new Vue({
    router,
    store,
    render: (h) => h(App)
  })
  /* eslint-enable no-new */

  return {app, router, store}
}
