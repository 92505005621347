import { render, staticRenderFns } from "./Button.vue?vue&type=template&id=482c81ed&scoped=true&"
import script from "./Button.vue?vue&type=script&lang=js&"
export * from "./Button.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./Button.vue?vue&type=style&index=0&id=482c81ed&lang=sass&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "482c81ed",
  "232017e1"
  
)

export default component.exports