<template>
  <Fragment>
    <slot :advice="featuredAdviceList" />
  </Fragment>
</template>

<script>
import dataWrapper from '@/mixins/data_wrapper'

import {mapActions, mapGetters, mapState} from 'vuex'

const MAX_ADVICE = 3

export default {
  name: 'TopAdviceDataWrapper',

  mixins: [dataWrapper],

  computed: {
    ...mapGetters(['featuredTagId']),

    ...mapState([
      'tagsListedAt',
      'featuredAdviceList',
      'featuredAdviceListedAt'
    ]),

    shouldGetAdvice() {
      return this.resourceStale(this.featuredAdviceListedAt)
    },

    shouldListTags() {
      return this.resourceStale(this.tagsListedAt)
    }
  },

  methods: {
    ...mapActions(['listTags', 'listFeaturedAdvice']),

    getData() {
      const params = {per_page: MAX_ADVICE}
      const tagId = this.featuredTagId

      return this.listFeaturedAdvice({params, tagId})
    },

    updateData() {
      if (this.shouldGetAdvice) {
        if (this.shouldListTags) {
          this.listTags().then(() => {
            this.getData()
          })
        } else {
          this.getData()
        }
      }
    }
  },

  serverPrefetch() {
    return this.listTags().then(() => {
      return this.getData()
    })
  },

  mounted() {
    this.updateData()
  },

  watch: {
    $route() {
      this.updateData()
    }
  }
}
</script>

<style lang="sass">
.top-advice-data-wrapper
</style>
