<template>
  <div class="obituaries-list">
    <Embed :fdsData="fdsData" />
  </div>
</template>

<script>
const FDS_DATE_STYLE = 'age_only'
const FDS_TYPE = 'notices'

import Embed from './Embed'

export default {
  name: 'ObituariesList',

  components: {
    Embed
  },

  data() {
    return {
      fdsData: {
        type: FDS_TYPE,
        'param-upcoming-only': false,
        'param-notices-date-style': FDS_DATE_STYLE,
        'param-sort-by': '',
        'param-sort-direction': ''
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.obituaries-list
</style>
