import {get} from '@/lib/request'

function adviceIndexPath() {
  return `/wp/v2/advice`
}

function advicePath(slug) {
  return `${adviceIndexPath()}/?slug=${slug}&_embed`
}

export function listAdvice(params = {}) {
  return get(`${adviceIndexPath()}?_embed`, params)
}

export function listFeaturedAdvice({tagId}) {
  const url = `${adviceIndexPath()}?tags=${tagId}&_embed`

  return get(url)
}

export function getAdvice(slug) {
  return get(advicePath(slug)).then(({data}) => {
    return data[0]
  })
}
