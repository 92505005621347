<template>
  <Container class="page-container" :class="{'has-banner': banner}">
    <slot />
  </Container>
</template>

<script>
import Container from '@/components/Container'

export default {
  name: 'PageContainer',

  props: {
    banner: {
      required: false,
      type: Boolean,
      default: true
    }
  },

  components: {
    Container
  }
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.page-container
  padding-top: 50px

  &.has-banner
    padding-top: 0

    @include media($media-gt-very-big)
      top: -100px
      z-index: 200
      position: relative
      align-items: stretch

    @media print
      top: 0!important

  ::v-deep .contained
    @include media($media-gt-very-big)
      padding-top: 75px!important

    @media print
      padding-top: 0!important

  ::v-deep .edge-left, ::v-deep .contained
    background: $color-light
</style>
