import Vue from 'vue'
import Vuex from 'vuex'

import * as getters from '@/store/getters'

import mutations from '@/store/mutations'
import actions from '@/store/actions'

Vue.use(Vuex)

export function createStore() {
  /* elsint-disabel no-new */
  return new Vuex.Store({
    actions,
    getters,
    modules: {},
    mutations,
    state: {
      activeRequests: 0,
      adviceBySlug: {},
      adviceList: [],
      adviceListedAt: null,
      affiliationList: [],
      affiliationsListedAt: null,
      categoriesBySlug: {},
      categoriesListedAt: null,
      categoryList: [],
      categoryProductLists: {},
      categoryProductsListedAt: {},
      featuredAdviceList: [],
      featuredAdviceListedAt: null,
      featuredServiceList: [],
      featuredServicesListedAt: null,
      locationsListedAt: null,
      locationList: [],
      locationServicesListedAt: null,
      locationServiceList: [],
      messageSubjectList: [],
      messageSubjectsListedAt: null,
      menusBySlug: {},
      packageList: [],
      packagesBySlug: {},
      packagesListedAt: null,
      productsListedAt: null,
      productList: [],
      productsBySlug: {},
      pagesBySlug: {},
      serviceList: [],
      servicesBySlug: {},
      servicesListedAt: null,
      tagsBySlug: {},
      tagsListedAt: null,
      testimonialList: [],
      testimonialsListedAt: null
    }
  })
  /* elsint-enable no-new */
}
