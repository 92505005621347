import { render, staticRenderFns } from "./Obituaries.vue?vue&type=template&id=5f2e5722&scoped=true&"
import script from "./Obituaries.vue?vue&type=script&lang=js&"
export * from "./Obituaries.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./Obituaries.vue?vue&type=style&index=0&id=5f2e5722&lang=sass&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "5f2e5722",
  "e86db65c"
  
)

export default component.exports