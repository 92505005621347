<template>
  <div class="locations">
    <ResponsiveImage
      class="locations-map"
      :imageVersions="imageVersions"
      description="Hawkins and Sons Ltd, locations"
      :width="windowWidth"
    />

    <Container v-if="isBig">
      <div class="cta">
        <h3>Drop in for a free consultation</h3>

        <p>
          We have two offices in the center of Leatherhead, Surrey. Both are
          within walking distance of the Registry office.
        </p>

        <Button icon :to="{name: 'contact'}">Visit us</Button>
      </div>
    </Container>

    <div class="cta" v-else>
      <h3>Drop in for a free consultation</h3>

      <p>
        We have two offices in the center of Leatherhead, Surrey. Both are
        within walking distance of the Registry office.
      </p>

      <Button icon :to="{name: 'contact'}">Visit us</Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button'
import Container from '@/components/Container'
import ResponsiveImage from '@/components/ResponsiveImage'
import throttle from 'lodash.throttle'

const WIDTH_BIG = 1200
const THROTTLE_INTERVAL = 100
const RESIZE_EVENT = 'resize'

export default {
  name: 'Locations',

  components: {
    Button,
    Container,
    ResponsiveImage
  },

  data() {
    return {
      imageVersions: [
        {
          width_px: 600,
          url: require('../assets/img/locations_tiny.png')
        },

        {
          width_px: 800,
          url: require('../assets/img/locations_small.png')
        },

        {
          width_px: 1080,
          url: require('../assets/img/locations_large.png')
        }
      ],

      // TODO: Width watching should be extracted when it is needed in another
      // place

      // track window width
      windowWidth: 0,

      // width event listener
      sizer: function() {}
    }
  },

  methods: {
    getWindowWidth() {
      this.windowWidth = window.innerWidth
    }
  },

  computed: {
    // TODO: move to vuex so its glabal
    isBig() {
      return this.windowWidth > WIDTH_BIG
    }
  },

  mounted() {
    this.getWindowWidth()

    this.sizer = window.addEventListener(
      RESIZE_EVENT,
      throttle(this.getWindowWidth, THROTTLE_INTERVAL)
    )
  },

  destroyed() {
    window.removeEventListener(RESIZE_EVENT, this.sizer)
  }
}
</script>

<style lang="sass" scoped>
@import '../stylesheets/base/_variables'
@import "../stylesheets/helpers/mixins"

$map-height: 680px

.locations
  position: relative

  h3
    font-size: 24px

    @include media($media-gt-big)
      font-size: $font-size-huge

  .locations-map
    @include media($media-gt-big)
      text-align: center
      position: relative
      z-index: 100

      img
        height: map-height

  .cta
    background: $color-light
    font-family: $font-main
    font-size: $font-size-medium

    padding: $pad * 2


  ::v-deep .container
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 200

    .contained
      display: flex
      align-items: center
      justify-content: flex-end

    .cta
      max-width: 600px

      @include media($media-gt-big)
        padding: $pad * 4

  .locations-map
    > img
      height: 350px

      @include media($media-gt-big)
        height: 100%
</style>
