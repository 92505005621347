<template>
  <LazyImg
    v-if="url"
    class="responsive-image"
    :description="description"
    :url="url"
    :egar="egar"
  />

  <div class="is-empty" v-else></div>
</template>

<script>
import LazyImg from '@/components/LazyImg'

export default {
  name: 'ResponsiveImage',

  props: {
    imageVersions: {
      type: Array,
      required: true
    },

    description: {
      type: String,
      default: ''
    },

    width: {
      type: Number,
      required: true
    },

    egar: {
      type: Boolean,
      default: () => false,
      required: false
    }
  },

  components: {
    LazyImg
  },

  data() {
    return {
      maxWidth: 0
    }
  },

  computed: {
    imageVersion() {
      const version = this.imageVersions.find((version) => {
        return version.width_px >= this.testWidth
      })

      return version || this.fallbackVersion
    },

    fallbackVersion() {
      const versions = this.imageVersions

      return versions.length ? versions[versions.length - 1] : {}
    },

    url() {
      return this.imageVersion.url || null
    },

    testWidth() {
      return Math.max(this.maxWidth, this.width)
    }
  },

  watch: {
    width(value) {
      this.maxWidth = Math.max(this.maxWidth, value)
    }
  }
}
</script>

<style lang="sass">
.responsive-image
</style>
