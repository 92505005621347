const FEATURED_SLUG = 'featured'

/**
 * @returns {boolean} is app loading something from server
 */
export function loading(state) {
  return state.activeRequests > 0 && process.env.VUE_APP_TARGET === 'client'
}

export function featuredTag(state) {
  return state.tagsBySlug[FEATURED_SLUG] || {}
}

export function featuredTagId(state) {
  const tag = featuredTag(state)
  return tag.id
}

export function messageSubjects(state) {
  return state.messageSubjectList.map((subject) => {
    return subject.title.rendered
  })
}
