import { render, staticRenderFns } from "./FeaturedServices.vue?vue&type=template&id=3b5d76ac&scoped=true&"
import script from "./FeaturedServices.vue?vue&type=script&lang=js&"
export * from "./FeaturedServices.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./FeaturedServices.vue?vue&type=style&index=0&id=3b5d76ac&lang=sass&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "3b5d76ac",
  "6e285413"
  
)

export default component.exports