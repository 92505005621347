<template>
  <div class="logo">
    <Sprite name="logo" />
  </div>
</template>

<script>
import Sprite from '@/components/Sprite'

export default {
  name: 'Logo',

  components: {
    Sprite
  }
}
</script>

<style lang="sass">
.logo
  display: inline-block

  svg
    width: 100%
    height: 100%
</style>
