<template>
  <div
    id="app"
    class="app"
    :class="{'is-navigating': navigating, 'is-scrolling': scrolling}"
  >
    <Loader :loading="loading" />

    <Header
      :loading="loading"
      :navigating="navigating"
      :scrolling="scrolling"
      @toggleNav="onToggleNav"
    />

    <main>
      <Transition name="fade" mode="out-in">
        <RouterView />
      </Transition>
    </main>

    <Footer class="footer" />

    <PrintFooter class="print-footer" />
  </div>
</template>

<script>
import throttle from 'lodash.throttle'

import {mapGetters} from 'vuex'

import Footer from '@/components/Footer'
import PrintFooter from '@/components/PrintFooter'
import Header from '@/components/Header'
import Loader from '@/components/Loader'

const NAVIGATING_CLASS = 'is-navigating'

export default {
  name: 'App',

  // Vue meta defaults
  metaInfo: {
    title: 'Hawkins and Sons Ltd.',

    meta: [
      {
        name: 'theme-color',
        content: '#670024'
      },

      {
        vmid: 'description',
        name: 'description',
        content:
          'Hawkins and Sons Ltd. Funeral directors in Leatherhead Surrey.'
      },

      {
        vmid: 'og:title',
        property: 'og:title',
        content: 'Hawkins and Sons Ltd.'
      },
      {vmid: 'og:type', property: 'og:type', content: 'article'},
      {
        vmid: 'og:url',
        property: 'og:url',
        content: 'https://hawkinsandsonsltd.com'
      },
      {
        vmid: 'og:image',
        property: 'og:image',
        content: 'http://example.com/image.jpg'
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content:
          'Hawkins and Sons Ltd. Funeral directors in Leatherhead Surrey.'
      },
      {
        vmid: 'og:site_name',
        property: 'og:site_name',
        content: 'Hawkins and Sons Ltd.'
      }
    ]
  },

  components: {
    Footer,
    Header,
    Loader,
    PrintFooter
  },

  data() {
    return {
      navigating: false,
      scrolling: false
    }
  },

  computed: {
    ...mapGetters(['loading'])
  },

  methods: {
    onToggleNav() {
      this.navigating = !this.navigating
    },

    checkScroll() {
      this.scrolling = !!window.scrollY
    }
  },

  watch: {
    navigating() {
      if (this.navigating) {
        document.body.classList.add(NAVIGATING_CLASS)
      } else {
        document.body.classList.remove(NAVIGATING_CLASS)
      }
    }
  },

  mounted() {
    // Keep track of weather user has scrolled down or not
    this.scrollListener = window.addEventListener(
      'scroll',
      throttle(() => {
        this.checkScroll()
      }),
      200,
      {trailing: true}
    )

    this.checkScroll()
  },

  destroyed() {
    window.removeEventListener('scroll', this.scrollListener)
  }
}
</script>

<style lang="sass">
@import "./stylesheets/base/variables"
@import "./stylesheets/helpers/mixins"
@import "./stylesheets/base/typography"

.app
  background: $color-light
  width: 100%
  min-height: 100vh
  display: flex
  flex-direction: column

  .footer
    @media print
      display: none!important

  .print-footer
    display: none

    @media print
      display: inline-block

  main
    @include media($media-gt-big)
      margin-top: 135px
</style>
