<template>
  <div class="breadcrumbs">
    <ul class="crumbs">
      <li class="breadcrumb">
        <RouterLink :to="{name: 'catalogue'}">Catalogue</RouterLink>
      </li>

      <Breadcrumb
        v-for="crumb in breadcrumbs"
        :key="crumb.id"
        :post="crumb"
        :active="crumb.id === category.id"
        class="crumb"
      />

      <li class="breadcrumb" v-for="crumb in crumbs" :key="crumb.label">
        <RouterLink v-if="crumb.route">
          {{ crumb.label }}
        </RouterLink>

        <span v-else>
          {{ crumb.label }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import Breadcrumb from './Breadcrumb'

/**
 * recurse up category tree to get ancestor categories for `category`
 *
 * @param category
 * @param categories
 * @param crumbs
 *
 * @returns Array - ancestor categories of category
 */
function doCrumbs(category, categories, crumbs) {
  if (category.parent === 0) {
    return [category].concat(crumbs)
  }

  const parent = categories.find((cat) => {
    return cat.id === category.parent
  })

  return doCrumbs(parent, categories, [category].concat(crumbs))
}

export default {
  name: 'Breadcrumbs',

  props: {
    category: {
      type: Object,
      required: true
    },

    categories: {
      type: Array,
      required: true
    },

    crumbs: {
      type: Array,
      default() {
        return []
      }
    }
  },

  components: {
    Breadcrumb
  },

  computed: {
    breadcrumbs() {
      return doCrumbs(this.category, this.categories, [])
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../stylesheets/base/variables"
@import "../../stylesheets/helpers/mixins"

.breadcrumbs
  padding: $pad 0

  @media print
    display: none

  .crumbs
    list-style: none
    padding: 0
    margin: 0

    .breadcrumb
      display: inline-block
      padding: $pad/2
      padding-left: 0

      &:after
        display: inline-block
        content: "/"
        padding-left: $pad/2

      &:last-of-type
        &:after
          display: none
</style>
