<template>
  <div class="banner" :class="{'has-image': !!imageVersions.length}">
    <ResponsiveImage
      class="background"
      :imageVersions="imageVersions"
      :width="width"
      :egar="true"
      v-if="!!imageVersions.length"
    />

    <Container class="words">
      <slot />
    </Container>
  </div>
</template>

<script>
import Container from '@/components/Container'
import ResponsiveImage from '@/components/ResponsiveImage'

import sizable from '@/mixins/sizable'

export default {
  name: 'Banner',

  mixins: [sizable],

  props: {
    imageVersions: {
      required: false,
      type: Array,
      default: () => []
    }
  },

  components: {
    Container,
    // LazyImg,
    ResponsiveImage
  }
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.banner
  width: 100%
  position: relative
  display: flex
  justify-content: center
  align-items: center
  overflow: hidden
  background: rgba($color-dark, 0.50)

  @include media($media-gt-big)
    padding-top: 190px

  @media print
    padding-top: 0!important
    background: #ffffff

  &.has-image
    padding-top: 0
    height: 350px

    @media print
      height: auto

    @include media($media-gt-big)
      height: 700px

    @include media($media-gt-very-big)
      height: 870px

    .background
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      pointer-events: none
      display: flex
      align-items: center
      justify-content: center
      color: #ffffff

      @media print
        display: none

      > img
        width: 100%
        height: 100%
        object-fit: cover
        max-width: 1920px

  ::v-deep .contained
    padding: 0
    display: flex
    flex-direction: column
    justify-content: center

    h1
      font-size: 32px
      font-family: $font-headings
      max-width: 850px

    p
      font-size: $font-size-large
      font-family: $font-headings
      max-width: 850px

      @media print
        font-size: 12pt

    @include media($media-gt-big)
      padding: 0!important

      h1
        font-size: 72px

      p
        font-size: 24px

  .words
    z-index: 100
    color: $color-light
    text-align: left
    z-index: 1
    background: rgba(0,0,0,0.25)
    width: 100%
    height: 100%
    display: flex
    padding: $pad

    @media print
      background: #ffffff
      color: #000000
      padding: 0
</style>
