import { render, staticRenderFns } from "./TopAdviceListItem.vue?vue&type=template&id=b896210a&scoped=true&"
import script from "./TopAdviceListItem.vue?vue&type=script&lang=js&"
export * from "./TopAdviceListItem.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./TopAdviceListItem.vue?vue&type=style&index=0&id=b896210a&lang=sass&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "b896210a",
  "3ba98ff6"
  
)

export default component.exports