<template>
  <div class="container">
    <div class="edge edge-left"></div>

    <div class="contained">
      <slot />
    </div>

    <div class="edge edge-right"></div>
  </div>
</template>

<script>
export default {
  name: 'Container'
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"

.container
  width: 100%
  display: flex
  align-items: stretch

  .contained
    width: 100%
    max-width: 1200px
    padding: 20px

    @media print
      padding: 0

  .edge
    flex: 1
</style>
