<template>
  <div class="phone" :class="{'is-collapsable': collapsable}">
    <a :href="`tel:${number}`" class="p-tel contact-link" title="Phone">
      <Sprite name="phone" class="phone-icon" />

      <div class="number value">
        {{ number }}
      </div>
    </a>

    <span v-if="blurb" class="spacer"></span>

    <span v-if="blurb" class="cta">
      Available 24 hours for emergency call outs
    </span>
  </div>
</template>

<script>
import Sprite from '@/components/Sprite'

const phone = process.env.VUE_APP_CONTACT_PHONE

export default {
  name: 'Phone',

  props: {
    blurb: {
      type: Boolean,
      required: false,
      default() {
        return true
      }
    },

    collapsable: {
      type: Boolean,
      required: false,
      default() {
        return true
      }
    }
  },

  data() {
    return {
      number: phone
    }
  },

  components: {
    Sprite
  }
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.phone
  display: flex
  align-items: center

  .contact-link
    display: flex
    align-items: center
    justify-content: center

  .phone-icon
    margin-right: 10px
    width: 22px
    height: 22px
    fill: $color-highlight

  a
    text-decoration: none
    color: $color-dark

  .value
    font-size: $font-size-large
    font-family: $font-headings

  .cta
    font-size: $font-size-small
    font-family: $font-main

  .spacer
    margin: 0 $pad
    background: rgba($color-underlines, 0.5)
    width: 1px
    height: 30px
    display: inline-block

  &.is-collapsable
    .value, .cta, .spacer
      display: none

      @include media($media-gt-big)
        display: inline-block

      @media print
        display: inline-block
</style>
