<template>
  <div class="page-home">
    <PostDataWrapper postType="page" slug="home">
      <template v-slot="slotProps">
        <Page :page="slotProps.post">
          <template v-slot="pageProps">
            <Banner :imageVersions="pageProps.featuredImageVersions">
              <h1>{{ pageProps.title }}</h1>
              <p>{{ pageProps.subHeading }}</p>
            </Banner>

            <TopAdviceDataWrapper>
              <template v-slot="slotProps">
                <TopAdvice :advice="slotProps.advice" />
              </template>
            </TopAdviceDataWrapper>

            <PostDataWrapper postType="page" slug="about">
              <template v-slot="slotProps">
                <PagePreview
                  v-if="slotProps.post.id"
                  :post="slotProps.post"
                  :headingOverride="pageProps.acf.about_heading"
                />
              </template>
            </PostDataWrapper>

            <Locations />

            <FeaturedServicesDataWrapper>
              <template v-slot="slotProps">
                <FeaturedServices
                  class="featured-services"
                  :services="slotProps.services"
                />
              </template>
            </FeaturedServicesDataWrapper>
          </template>
        </Page>
      </template>
    </PostDataWrapper>
  </div>
</template>

<script>
import page from '@/mixins/page'

import Banner from '@/components/Banner'
import FeaturedServicesDataWrapper from '@/components/FeaturedServicesDataWrapper'
import FeaturedServices from '@/components/FeaturedServices'
import Locations from '@/components/Locations'
import PagePreview from '@/components/PagePreview'
import PostDataWrapper from '@/components/PostDataWrapper'
import TopAdvice from '@/components/TopAdvice'
import TopAdviceDataWrapper from '@/components/TopAdviceDataWrapper'

export default {
  name: 'Home',

  mixins: [page],

  components: {
    Banner,
    FeaturedServicesDataWrapper,
    FeaturedServices,
    Locations,
    PagePreview,
    PostDataWrapper,
    TopAdvice,
    TopAdviceDataWrapper
  }
}
</script>

<style lang="sass" scoped="true">
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.page-home
  .page-preview
    @include media($media-gt-big)
      margin-top: -80px

  ::v-deep .post-preview
    flex-direction: column!important
</style>
