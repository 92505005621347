import { render, staticRenderFns } from "./Navigation.vue?vue&type=template&id=d891f178&scoped=true&"
import script from "./Navigation.vue?vue&type=script&lang=js&"
export * from "./Navigation.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./Navigation.vue?vue&type=style&index=0&id=d891f178&lang=sass&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "d891f178",
  "56cc00c2"
  
)

export default component.exports