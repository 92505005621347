export const REQUEST_ADVICE = 'REQUEST_ADVICE'
export const RECEIVE_ADVICE = 'RECEIVE_ADVICE'
export const REQUESTED_ADVICE = 'REQUESTED_ADVICE'

export const REQUEST_ADVICE_LIST = 'REQUEST_ADVICE_LIST'
export const RECEIVE_ADVICE_LIST = 'RECEIVE_ADVICE_LIST'
export const REQUESTED_ADVICE_LIST = 'REQUESTED_ADVICE_LIST'

export const REQUEST_FEATURED_ADVICE_LIST = 'REQUEST_FEATURED_ADVICE_LIST'
export const RECEIVE_FEATURED_ADVICE_LIST = 'RECEIVE_FEATURED_ADVICE_LIST'
export const REQUESTED_FEATURED_ADVICE_LIST = 'REQUESTED_FEATURED_ADVICE_LIST'

export const REQUEST_AFFILIATION_LIST = 'REQUEST_AFFILIATION_LIST'
export const RECEIVE_AFFILIATION_LIST = 'RECEIVE_AFFILIATION_LIST'
export const REQUESTED_AFFILIATION_LIST = 'REQUESTED_AFFILIATION_LIST'

export const REQUEST_CATEGORY_LIST = 'REQUEST_CATEGORY_LIST'
export const RECEIVE_CATEGORY_LIST = 'RECEIVE_CATEGORY_LIST'
export const REQUESTED_CATEGORY_LIST = 'REQUESTED_CATEGORY_LIST'

export const REQUEST_CATEGORY = 'REQUEST_CATEGORY'
export const RECEIVE_CATEGORY = 'RECEIVE_CATEGORY'
export const REQUESTED_CATEGORY = 'REQUESTED_CATEGORY'

export const REQUEST_MENU = 'REQUEST_MENU'
export const RECEIVE_MENU = 'RECEIVE_MENU'
export const REQUESTED_MENU = 'REQUESTED_MENU'

export const REQUEST_PACKAGE = 'REQUEST_PACKAGE'
export const RECEIVE_PACKAGE = 'RECEIVE_PACKAGE'
export const REQUESTED_PACKAGE = 'REQUESTED_PACKAGE'

export const REQUEST_PACKAGE_LIST = 'REQUEST_PACKAGE_LIST'
export const RECEIVE_PACKAGE_LIST = 'RECEIVE_PACKAGE_LIST'
export const REQUESTED_PACKAGE_LIST = 'REQUESTED_PACKAGE_LIST'

export const REQUEST_PAGE = 'REQUEST_PAGE'
export const RECEIVE_PAGE = 'RECEIVE_PAGE'
export const REQUESTED_PAGE = 'REQUESTED_PAGE'

export const REQUEST_SERVICE = 'REQUEST_SERVICE'
export const RECEIVE_SERVICE = 'RECEIVE_SERVICE'
export const REQUESTED_SERVICE = 'REQUESTED_SERVICE'

export const REQUEST_SERVICE_LIST = 'REQUEST_SERVICE_LIST'
export const RECEIVE_SERVICE_LIST = 'RECEIVE_SERVICE_LIST'
export const REQUESTED_SERVICE_LIST = 'REQUESTED_SERVICE_LIST'

export const REQUEST_FEATURED_SERVICE_LIST = 'REQUEST_FEATURED_SERVICE_LIST'
export const RECEIVE_FEATURED_SERVICE_LIST = 'RECEIVE_FEATURED_SERVICE_LIST'
export const REQUESTED_FEATURED_SERVICE_LIST = 'REQUESTED_FEATURED_SERVICE_LIST'

export const REQUEST_TAG_LIST = 'REQUEST_TAG_LIST'
export const RECEIVE_TAG_LIST = 'RECEIVE_TAG_LIST'
export const REQUESTED_TAG_LIST = 'REQUESTED_TAG_LIST'

export const REQUEST_LOCATION_SERVICE_LIST = 'REQUEST_LOCATION_SERVICE_LIST'
export const RECEIVE_LOCATION_SERVICE_LIST = 'RECEIVE_LOCATION_SERVICE_LIST'
export const REQUESTED_LOCATION_SERVICE_LIST = 'REQUESTED_LOCATION_SERVICE_LIST'

export const REQUEST_PRODUCT_LIST = 'REQUEST_PRODUCT_LIST'
export const RECEIVE_PRODUCT_LIST = 'RECEIVE_PRODUCT_LIST'
export const REQUESTED_PRODUCT_LIST = 'REQUESTED_PRODUCT_LIST'
export const REQUEST_PRODUCT = 'REQUEST_PRODUCT'
export const RECEIVE_PRODUCT = 'RECEIVE_PRODUCT'
export const REQUESTED_PRODUCT = 'REQUESTED_PRODUCT'

export const REQUEST_TESTIMONIAL_LIST = 'REQUEST_TESTIMONIAL_LIST'
export const RECEIVE_TESTIMONIAL_LIST = 'RECEIVE_TESTIMONIAL_LIST'
export const REQUESTED_TESTIMONIAL_LIST = 'REQUESTED_TESTIMONIAL_LIST'

export const REQUEST_LOCATION_LIST = 'REQUEST_LOCATION_LIST'
export const RECEIVE_LOCATION_LIST = 'RECEIVE_LOCATION_LIST'
export const REQUESTED_LOCATION_LIST = 'REQUESTED_LOCATION_LIST'

export const REQUEST_MESSAGE_SUBJECT_LIST = 'REQUEST_MESSAGE_SUBJECT_LIST'
export const RECEIVE_MESSAGE_SUBJECT_LIST = 'RECEIVE_MESSAGE_SUBJECT_LIST'
export const REQUESTED_MESSAGE_SUBJECT_LIST = 'REQUESTED_MESSAGE_SUBJECT_LIST'
