export default {
  data() {
    return {
      pagination: {
        page: 1,
        per_page: 10,
        total_pages: 1
      }
    }
  },

  methods: {
    // Thanks wordpress... total pages only comes back as a response header, so
    // this can only be updated this way.
    postsLoaded({totalPages}) {
      const oldTotalPages = this.pagination.total_pages

      if (oldTotalPages !== totalPages) {
        this.pagination.page = 1
      }

      this.pagination.total_pages = totalPages
    }
  }
}
