import {get} from '@/lib/request'

function categoryProductIndexPath(categoryId = '') {
  return `/wp/v2/products?categories=${categoryId}`
}

function categoryProductPath(categoryId, slug) {
  return `${categoryProductIndexPath(categoryId)}&slug=${slug}&_embed`
}

function productIndexPath() {
  return `/wp/v2/products`
}

function productPath(slug) {
  return `${productIndexPath()}?slug=${slug}&_embed`
}

export function listCategoryProducts(categoryId, params) {
  return get(`${categoryProductIndexPath(categoryId)}&_embed`, params)
}

export function getCategoryProduct(categoryId, slug) {
  return get(categoryProductPath(categoryId, slug)).then(({data}) => {
    return data[0]
  })
}

export function getProduct(slug) {
  return get(productPath(slug)).then(({data}) => {
    return data[0]
  })
}
