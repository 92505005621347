<template>
  <div v-if="hasAdvice" class="related-advice">
    <ul class="related-advice-list">
      <PostDataWrapper
        v-for="article in advice"
        :key="article.ID"
        postType="advice"
        postTypePlural="advice"
        :slug="article.post_name"
      >
        <template v-slot:default="slotProps">
          <AdviceItem :post="slotProps.post" />
        </template>
      </PostDataWrapper>
    </ul>
  </div>
</template>

<script>
import PostDataWrapper from '@/components/PostDataWrapper'
import AdviceItem from './AdviceItem'

export default {
  name: 'RelatedAdvice',

  props: {
    advice: {
      type: Array,
      required: true
    }
  },

  components: {
    AdviceItem,
    PostDataWrapper
  },

  computed: {
    hasAdvice() {
      return this.advice.length
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../../stylesheets/base/variables"
@import "../../../stylesheets/helpers/mixins"

.related-advice
  .related-advice-list
    @include list
</style>
