<template>
  <button
    class="button"
    :class="{'with-border': border, [`is-${type}`]: 1}"
    @click="$emit('click')"
  >
    <div class="inner">
      <span class="label">
        <slot />
      </span>

      <Sprite v-if="icon" :name="iconName" />
    </div>
  </button>
</template>

<script>
import Sprite from '@/components/Sprite'

export default {
  name: 'Button',

  props: {
    icon: {
      type: Boolean,
      default: false
    },

    iconName: {
      required: false,
      default() {
        return 'arrow-right'
      }
    },

    border: {
      type: Boolean,
      default: false
    },

    dark: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Sprite
  },

  computed: {
    type() {
      return this.dark ? 'dark' : 'light'
    },

    typeClass() {
      return `is-${this.type}`
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../../stylesheets/base/variables"
@import "../../../stylesheets/helpers/mixins"

.button
  background: $color-light
  cursor: pointer
  display: flex
  align-items: center
  background: transparent
  padding: $pad/2 0
  border: none

  .inner
    border-bottom: 2px solid $color-highlight
    display: flex
    align-items: center
    font-weight: bold
    font-size: $font-size-small
    font-family: $font-main
    text-transform: uppercase
    letter-spacing: 1.2px
    color: $color-dark
    padding: $pad/2 0

  .label
    padding-right: $pad/2

  .sprite
    width: 18px
    height: 18px

  &.with-border
    border: 1px solid $color-dark
    padding-left: $pad
    padding-right: $pad

  &.is-dark
    color: $color-light
    border-color: $color-light

    .sprite
      fill: $color-light

  &.is-light
    color: $color-dark
    border-color: $color-dark

    .sprite
      fill: $color-highlight
</style>
