import {request} from '@/lib/request'

function menuPath(slug) {
  return `/menus/v1/menus/${slug}`
}

export function getMenu({slug}) {
  return request('get', menuPath(slug)).then(({data}) => {
    return data
  })
}
