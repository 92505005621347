<template>
  <li class="advice-item">
    <h3>{{ title }}</h3>
    <p>{{ subHeading }}</p>

    <Button icon :to="route">Read more</Button>
  </li>
</template>

<script>
import post from '@/mixins/post'
import Button from '@/components/Button'

export default {
  name: 'AdviceItem',

  mixins: [post],

  props: {
    post: {
      type: Object,
      required: true
    }
  },

  components: {
    Button
  },

  computed: {
    route() {
      return {name: 'advice.advice', params: {slug: this.slug}}
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../../stylesheets/base/variables"
@import "../../../stylesheets/helpers/mixins"

.advice-item
  padding: $pad 0

  h3
    font-size: 24px
    padding-bottom: $pad
    border-bottom: 2px solid $color-underlines
    margin: 0
</style>
