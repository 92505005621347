import input from '@/mixins/input'

import {validationMixin} from 'vuelidate'

import Button from '@/components/forms/inputs/Button'
import Input from '@/components/forms/inputs/Input'
import Loader from '@/components/forms/Loader'
import Select from '@/components/forms/inputs/Select'
import TextArea from '@/components/forms/inputs/TextArea'

export default {
  mixins: [input, validationMixin],

  props: {
    errors: {
      type: Object,
      required: true
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Button,
    Input,
    Loader,
    Select,
    TextArea
  },

  methods: {
    onSubmit() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$emit('submit', this.resource)
      }
    }
  }
}
