<template>
  <div class="page-obituaries">
    <PostDataWrapper postType="page" slug="obituaries">
      <template v-slot="slotProps">
        <Page :page="slotProps.post">
          <template v-slot="pageProps">
            <Banner :image-versions="pageProps.featuredImageVersions">
              <h1>{{ pageProps.title }}</h1>
              <p>{{ pageProps.subHeading }}</p>
            </Banner>
          </template>
        </Page>
      </template>
    </PostDataWrapper>

    <PageContainer>
      <ObituaryList />
    </PageContainer>
  </div>
</template>

<script>
import page from '@/mixins/page'

import Banner from '@/components/Banner'
import PageContainer from '@/components/PageContainer'
import ObituaryList from '@/components/obituaries/ObituaryList'

export default {
  name: 'Obituaries',

  mixins: [page],

  components: {
    Banner,
    ObituaryList,
    PageContainer
  }
}
</script>

<style lang="sass" scoped>
.page-obituaries
</style>
