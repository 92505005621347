<template>
  <Container class="bottom">
    <ul class="footer-links-list">
      <li>
        <RouterLink :to="{name: 'privacy'}">Privacy policy</RouterLink>
      </li>

      <li>
        <RouterLink :to="{name: 'terms'}">Full terms and conditions</RouterLink>
      </li>
    </ul>

    <p>&copy; Copyright L Hawkins &amp; Sons. All rights reserved.</p>
  </Container>
</template>

<script>
import Container from '@/components/Container'
export default {
  name: 'Bottom',

  components: {
    Container
  }
}
</script>

<style lang="sass" scoped>
@import "../../stylesheets/base/variables"
@import "../../stylesheets/helpers/mixins"

.bottom
  ::v-deep .contained
    display: flex
    flex-direction: column
    border-top: 1px solid $color-underlines
    padding: $pad
    font-size: $font-size-small
    font-family: $font-main
    color: $color-text-light

    p
      font-size: $font-size-small
      font-family: $font-main
      color: $color-text-light

    @include media($media-gt-big)
      flex-direction: row
      justify-content: space-between
      align-items: center

  .footer-links-list
    @include list
    padding-bottom: $pad
    display: flex

    @include media($media-gt-big)
      padding: 0

    @media print
      display: none

    li
      margin-right: $pad / 2

      a
        color: $color-text-light
</style>
