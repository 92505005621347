<template>
  <Container class="top-advice">
    <ul class="top-advice-list">
      <TopAdviceListItem
        v-for="article in advice"
        :key="article.id"
        :post="article"
      />
    </ul>
  </Container>
</template>

<script>
import Container from '@/components/Container'
import TopAdviceListItem from './TopAdviceListItem'

export default {
  name: 'TopAdvice',

  props: {
    advice: {
      type: Array,
      required: true
    }
  },

  components: {
    Container,
    TopAdviceListItem
  }
}
</script>

<style lang="sass" scoped>
@import "../../stylesheets/base/variables"
@import "../../stylesheets/helpers/mixins"

.top-advice
  @include media($media-gt-big)
    top: -100px
    z-index: 200
    position: relative
    align-items: stretch

  ::v-deep .contained, ::v-deep .edge-left
    background: $color-light-background

  .top-advice-list
    @include list

    @include media($media-gt-big)
      display: flex

      .top-advice-list-item
        &:first-of-type
          padding-left: 0

        &:last-of-type
          padding-right: 0
</style>
