<template>
  <div class="cta">
    <h3>{{ title }}</h3>

    <p>Our funeral specialists are on hand, and ready to help.</p>

    <Phone :collapsable="false" :blurb="false" />
  </div>
</template>

<script>
import Phone from '@/components/Phone'

export default {
  name: 'CTA',

  props: {
    title: {
      required: false,
      default() {
        return 'Need advice?'
      }
    }
  },

  components: {
    Phone
  }
}
</script>

<style lang="sass" scoped>
@import "../../stylesheets/base/variables"
@import "../../stylesheets/helpers/mixins"

.cta
  padding: $pad 0

  p
    font-size: 14px

  h3
    font-size: 24px
    padding-bottom: $pad
    border-bottom: 2px solid $color-underlines
    margin: 0

  ::v-deep .phone
    .number
      font-size: 16px
      color: $color-highlight
</style>
