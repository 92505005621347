import { render, staticRenderFns } from "./Breadcrumb.vue?vue&type=template&id=b4501914&scoped=true&"
import script from "./Breadcrumb.vue?vue&type=script&lang=js&"
export * from "./Breadcrumb.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./Breadcrumb.vue?vue&type=style&index=0&id=b4501914&lang=sass&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "b4501914",
  "2a0594d0"
  
)

export default component.exports