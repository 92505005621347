<template>
  <Fragment>
    <slot
      :content="content"
      :title="title"
      :featuredImageUrl="featuredImageUrl"
      :featuredImageVersions="featuredImageVersions"
      :subHeading="subHeading"
      :relatedAdvice="relatedAdvice"
      :relatedServices="relatedServices"
      :relatedPackages="relatedPackages"
      :acf="acf"
    ></slot>
  </Fragment>
</template>

<script>
import post from '@/mixins/post'

import {Fragment} from 'vue-fragment'

// TODO: escaped and not escaped versions of title are needed

export default {
  name: 'Page',

  mixins: [post],

  props: {
    page: {
      required: true,
      type: Object
    }
  },

  components: {
    Fragment
  },

  computed: {
    post() {
      return this.page
    },

    subHeading() {
      return this.acf.sub_heading
    },
    relatedAdvice() {
      return this.acf.related_advice || []
    },

    relatedServices() {
      return this.acf.related_services || []
    },

    relatedPackages() {
      return this.acf.related_packages || []
    }
  },

  metaInfo() {
    return {
      title: `${this.title} | Hawkins and Sons Ltd.`,
      meta: this.metaTags || []
    }
  }
}
</script>

<style lang="sass" scoped>
.view
</style>
