<script>
export default {
  data() {
    return {
      affiliations: [
        {
          title: 'Independent Funeral Standards Organisation',
          src:
            'https://lhawkinsandsonsltd.co.uk/wp-content/uploads/2022/06/ifso.png'
        },

        {
          title: 'Golden Charter',
          src:
            'https://lhawkinsandsonsltd.co.uk/wp-content/uploads/2020/05/gc.png'
        },

        {
          title:
            'The National Society Of Allied And Independent Funeral Directors',
          src:
            'https://lhawkinsandsonsltd.co.uk/wp-content/uploads/2020/05/SAIF_logo_MEMBER.png'
        },

        {
          title: 'National association of memorial masons',
          src:
            'https://lhawkinsandsonsltd.co.uk/wp-content/uploads/2020/02/Image-24.png'
        }
      ]
    }
  }
}
</script>

<template>
  <div class="print-footer">
    <div class="affiliations">
      <ul class="affiliations-list">
        <li v-for="affiliation in affiliations" :key="affiliation.title">
          <img
            class="affiliation-logo"
            :src="affiliation.src"
            :alt="affiliation.title"
          />
        </li>
      </ul>
    </div>

    <div class="copyright">
      &copy; L Hawkins and Sons Ltd.
    </div>
  </div>
</template>

<style lang="sass" scpoed>
@import '../stylesheets/base/variables'
@import '../stylesheets/helpers/mixins'

.print-footer
  .affiliations-list
    @include list
    width: 100%
    align-items: center
    display: flex
    flex-wrap: wrap
    justify-content: space-between

    .affiliation-logo
      width: 100%
      max-width: 70px
</style>
