<template>
  <router-link
    :to="to"
    class="button"
    :class="{
      'with-icon': icon,
      'is-underlined': underlined,
      'with-border': border,
      [`is-${type}`]: 1
    }"
  >
    <div class="inner">
      <span class="label">
        <slot />
      </span>

      <Sprite v-if="icon" name="arrow-right" />
    </div>
  </router-link>
</template>

<script>
import Sprite from '@/components/Sprite'

export default {
  name: 'Button',

  props: {
    to: {
      type: [Object, String],
      required: true
    },

    icon: {
      type: Boolean,
      default: false
    },

    border: {
      type: Boolean,
      default: false
    },

    dark: {
      type: Boolean,
      default: false
    },

    underlined: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Sprite
  },

  computed: {
    type() {
      return this.dark ? 'dark' : 'light'
    },

    typeClass() {
      return `is-${this.type}`
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.button
  background: $color-light
  cursor: pointer
  display: flex
  align-items: center
  background: transparent
  padding: $pad/2 0
  border: none

  .inner
    display: flex
    align-items: center
    font-weight: bold
    font-size: $font-size-small
    font-family: $font-main
    text-transform: uppercase
    letter-spacing: 1.2px
    color: $color-dark
    padding: $pad/2 0

  &:hover
    text-decoration: none

  &.with-icon
    .label
      padding-right: $pad/2

  .sprite
    width: 18px
    height: 18px

  &.is-underlined
    .inner
      border-bottom: 2px solid $color-highlight

  &.with-border
    border: 1px solid $color-dark
    padding-left: $pad
    padding-right: $pad

  &.is-dark
    color: $color-light
    border-color: $color-light

    .sprite
      fill: $color-light

  &.is-light
    color: $color-dark
    border-color: $color-dark

    .sprite
      fill: $color-highlight
</style>
