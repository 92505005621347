<template>
  <div
    class="page-contact"
    id="parent-business"
    itemprop="branchOf"
    itemscope
    itemtype="http://schema.org/Corporation"
  >
    <PostDataWrapper postType="page" slug="contact">
      <template v-slot="slotProps">
        <Page :page="slotProps.post">
          <template v-slot="pageProps">
            <Banner :image-versions="pageProps.featuredImageVersions">
              <h1>{{ pageProps.title }}</h1>
              <p>{{ pageProps.subHeading }}</p>
            </Banner>

            <Container class="phone">
              <div>
                <h3>
                  Call us
                </h3>

                <p>Our friendly team are ready and waiting to talk to you.</p>

                <Phone :collapsable="false" />
              </div>
            </Container>

            <Container>
              <div v-html="pageProps.content"></div>
            </Container>
          </template>
        </Page>
      </template>
    </PostDataWrapper>

    <Container class="visit">
      <section>
        <h2>Visit us</h2>

        <p>
          <span itemprop="name">L Hawkins and Sons Ltd.</span> have two premises
          in Leatherhead, Surrey:
        </p>

        <ul class="cols locations">
          <Location
            class="col"
            v-for="location in locations"
            :location="location"
            :key="location.id"
            :services="locationServices"
          />
        </ul>
      </section>

      <section>
        <h2>Email us</h2>

        <transition name="fade" mode="out-in">
          <p v-if="!sent">
            Alternatively, please fill in the form below to email us, or email
            us at
            <a
              href="mailto:office@hawkinsandsons.net"
              class="highlight"
              itemprop="email"
            >
              office@hawkinsandsons.net
            </a>
            .
          </p>
        </transition>

        <transition name="fade" mode="out-in">
          <PostsDataWrapper postType="messageSubject" :params="{}">
            <template v-slot="subjectProps">
              <ContactForm
                v-model="message"
                v-if="!sent"
                :loading="loading"
                :errors="errors"
                @submit="onSubmit"
                :subjects="subjectProps.posts"
              />

              <p v-else>Thanks. We will get back to you soon.</p>
            </template>
          </PostsDataWrapper>
        </transition>
      </section>
    </Container>
  </div>
</template>

<script>
import page from '@/mixins/page'

import {createMessage} from '@/lib/contact'

import {listLocations} from '@/lib/locations'
import {listLocationServices} from '@/lib/location_services'

import Banner from '@/components/Banner'
import Container from '@/components/Container'
import ContactForm from '@/components/forms/ContactForm'
import Location from '@/components/Location'
import Phone from '@/components/Phone'

import PostsDataWrapper from '@/components/PostsDataWrapper'

// TODO: Some way to request this info from back end instead
const CONTACT_FORM_ID = 5
const CONTACT_FORM_NAME_KEY = 'contact-name'
const CONTACT_FORM_EMAIL_KEY = 'contact-email'
const CONTACT_FORM_SUBJECT_KEY = 'message-subject'
const CONTACT_FORM_MESSAGE_KEY = 'message-body'
const CONTACT_FORM_ERROR_MESSAGE =
  'Could not deliver message at this time. Please try again later.'
const SUCCESS_STATUS = 'mail_sent'

const WPCF7_UNIT_TAG = 'koba_contact_form'

export default {
  name: 'Contact',

  mixins: [page],

  components: {
    Banner,
    Container,
    ContactForm,
    Location,
    Phone,
    PostsDataWrapper
  },

  data() {
    return {
      message: {},
      errors: {},
      loading: false,
      sent: false,
      locations: [],
      locationServices: []
    }
  },

  computed: {
    formData() {
      let data = new FormData()

      data.append(CONTACT_FORM_NAME_KEY, this.message.name)
      data.append(CONTACT_FORM_EMAIL_KEY, this.message.email)
      data.append(CONTACT_FORM_SUBJECT_KEY, this.message.subject)
      data.append(CONTACT_FORM_MESSAGE_KEY, this.message.message)
      data.append(CONTACT_FORM_MESSAGE_KEY, this.message.message)
      data.append('_wpcf7_unit_tag', WPCF7_UNIT_TAG)

      return data
    }
  },

  methods: {
    onSubmit() {
      this.loading = true

      createMessage(CONTACT_FORM_ID, this.formData).then(({data}) => {
        if (data.status === SUCCESS_STATUS) {
          this.sent = true
        } else {
          alert(CONTACT_FORM_ERROR_MESSAGE)
        }

        this.loading = false
      })
    },

    getData() {
      const promises = []

      promises.push(
        listLocations().then((locations) => {
          this.locations = locations.data
        })
      )

      promises.push(
        listLocationServices().then((locationServices) => {
          this.locationServices = locationServices.data
        })
      )

      return promises
    },

    shouldGetData() {
      return !this.locations.length && !this.locationServices.length
    }
  },

  mounted() {
    if (this.shouldGetData) {
      this.getData()
    }
  },

  serverPrefetch() {
    return this.getData()
  }
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.page-contact
  .location
    padding: $pad 0
    @include row

    @include media($media-gt-big)
      padding: 0 $pad
      @include column

  .locations
    @include list
    padding-top: $pad*2

    @include media($media-gt-big)
      display: flex

    .col
      @include media($media-gt-big)
        padding: $pad

        &:first-of-type
          padding-left: 0

        &:last-of-type
          padding-right: 0

  .address
    > span
      display: block

  .phone
    background: $color-highlight
    color: $color-light

    ::v-deep .phone
      justify-content: center

      .value
        color: $color-light

      .phone-icon
        fill: $color-light

    @include media($media-gt-big)
      padding: $pad 0

    ::v-deep .contained
      min-height: 180px
      display: flex
      align-items: center
      justify-content: center
      text-align: center

      h2
        margin: 0 0 $pad 0

    .small
      font-size: 66%
      color: $color-light

  h2
    margin-top: 40px

  h3
    margin: $pad * 2 0

  .highlight
    color: $color-highlight
    font-weight: bold
</style>
