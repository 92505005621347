<template>
  <div class="textarea" :class="classList">
    <transition name="fade">
      <label v-if="hasFocus || hasValue" class="focused-label" :for="_uid">{{
        placeholder
      }}</label>

      <label v-else class="focused-label">&nbsp;</label>
    </transition>

    <textarea
      :class="classList"
      v-model="resource"
      :id="_uid"
      @focus="onFocus"
      @blur="onBlur"
    ></textarea>

    <transition name="fade">
      <label
        v-if="!hasFocus && !hasValue"
        class="unfocused-label"
        :for="_uid"
        >{{ placeholder }}</label
      >
    </transition>

    <Error :error="error" />
  </div>
</template>

<script>
import input from '@/mixins/input'

import Error from '../Error'

export default {
  name: 'TextArea',

  mixins: [input],

  props: {
    placeholder: {
      type: String,
      required: true
    },

    error: {
      type: String,
      required: false
    },

    helperText: {
      type: String,
      required: false
    }
  },

  components: {
    Error
  },

  data() {
    return {
      hasFocus: false
    }
  },

  computed: {
    hasValue() {
      return !!this.value
    },

    classList() {
      return {
        'has-focus': this.hasFocus,
        'has-value': this.hasValue,
        'is-invalid': !!this.error
      }
    }
  },

  methods: {
    onFocus() {
      this.hasFocus = true
    },

    onBlur() {
      this.hasFocus = false
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../../stylesheets/base/variables"
@import "../../../stylesheets/helpers/mixins"

.textarea
  position: relative

  label
    display: block
    text-align: left
    width: 100%
    color: lighten($color-dark, 25)

  .focused-label
    font-size: 12px

  textarea
    width: 100%
    resize: none
    overflow: auto
    border: 1px solid $color-dark
    background: $color-light
    border-radius: 2px
    padding: $pad / 5 $pad / 4
    height: 500px
    max-height: 50px
    transition: max-height $transition-duration $transition-easing

    &:focus
      max-height: 250px

  &.has-focus
    label
      color: $color-dark

  &.is-invalid.has-focus
    label
      color: $color-error

  .unfocused-label
    position: absolute
    top: 5px
    left: 10px
</style>
