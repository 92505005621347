<template>
  <li class="testimonial">
    <div class="left">
      <Sprite class="testimonial-flare" name="quote-open" />
    </div>

    <div class="right">
      <div class="testimonial-body" v-html="content"></div>

      <h4 class="testimonial-author">{{ title }}</h4>
    </div>
  </li>
</template>

<script>
import post from '@/mixins/post'

import Sprite from '@/components/Sprite'

export default {
  name: 'Testimonial',

  mixins: [post],

  props: {
    testimonial: {
      type: Object,
      required: true
    }
  },

  components: {
    Sprite
  },

  computed: {
    post() {
      return this.testimonial
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../stylesheets/base/variables"
@import "../../stylesheets/helpers/mixins"

.testimonial
  display: flex
  padding: $pad 0
  @include row

  @include media($media-gt-big)
    padding: 0 $pad
    @include column
    width: 50%

  .testimonial-flare
    width: 24px
    height: 24px
    fill: $color-highlight

  .left
    padding-right: $pad / 2

  .testimonial-body
    ::v-deep p
      font-family: $font-flare
      @include text(16px, 18px, $media-gt-big)

      &:first-child
        margin-top: 0

      &:last-child
        margin-bottom: 0

  .testimonial-author
    font-family: $font-main
    font-size: $font-size-small
    color: $color-dark
</style>
