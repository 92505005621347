import {get} from '@/lib/request'

function serviceIndexPath() {
  return `/wp/v2/services`
}

function servicePath(slug) {
  return `${serviceIndexPath()}/?slug=${slug}&_embed`
}

export function listServices(params) {
  return get(`${serviceIndexPath()}?_embed`, params)
}

export function listFeaturedServices({tagId}) {
  const url = `${serviceIndexPath()}?tags=${tagId}&_embed`

  return get(url)
}

export function getService(slug) {
  return get(servicePath(slug)).then(({data}) => {
    return data[0]
  })
}
