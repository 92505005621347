<template>
  <div class="page-catalogue-category-product">
    <Page :page="product" v-if="product">
      <PageContainer>
        <Breadcrumbs
          v-if="hasCategory(category) && product.title"
          :categories="categoryList"
          :category="category"
          :crumbs="[{label: product.title.rendered}]"
        />

        <Product v-if="product" :product="product" />
      </PageContainer>
    </Page>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/catalogue/Breadcrumbs'
import PageContainer from '@/components/PageContainer'
import Product from '@/components/catalogue/Product'
import Page from '@/components/Page'

import {mapActions, mapState} from 'vuex'

export default {
  name: 'CatalogueCategoryProduct',

  props: {
    slug: {
      required: true,
      type: String
    }
  },

  components: {
    Breadcrumbs,
    PageContainer,
    Product,
    Page
  },

  methods: {
    ...mapActions(['listCategories', 'getProduct']),

    productParams(category) {
      const categoryId = category.id
      return {...this.pagination, categoryId}
    },

    /**
     * Gross but prevents weird serverside crash
     */
    hasCategory(category) {
      return category && Object.keys(category).includes('parent')
    },

    getData() {
      const promises = []

      // TODO: if should get...

      promises.push(this.getProduct({slug: this.slug, args: {}}))
      promises.push(this.listCategories({}))

      return Promise.all(promises)
    }
  },

  computed: {
    ...mapState(['productsBySlug', 'categoryList']),

    product() {
      return this.productsBySlug[this.slug]
    },

    categoryId() {
      return this.product.categories[0]
    },

    category() {
      if (!this.product) {
        return {}
      }

      return (
        this.categoryList.find((cat) => {
          return cat.id === this.categoryId
        }) || {}
      )
    },

    categorySlug() {
      return this.category.slug
    }
  },

  serverPrefetch() {
    return this.getData()
  },

  mounted() {
    return this.getData()
  }
}
</script>

<style lang="sass" scoped>
.page-catalogue-category-product
  margin-top: 100px
</style>
