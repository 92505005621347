import { render, staticRenderFns } from "./CategoryDataWrapper.vue?vue&type=template&id=61eaad76&"
import script from "./CategoryDataWrapper.vue?vue&type=script&lang=js&"
export * from "./CategoryDataWrapper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  "44e0e8d8"
  
)

export default component.exports