import { render, staticRenderFns } from "./Testimonials.vue?vue&type=template&id=23e6e50b&scoped=true&"
import script from "./Testimonials.vue?vue&type=script&lang=js&"
export * from "./Testimonials.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./Testimonials.vue?vue&type=style&index=0&id=23e6e50b&lang=sass&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "23e6e50b",
  "a651e19e"
  
)

export default component.exports