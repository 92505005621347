<template>
  <Container class="featured-services">
    <h2>Popular services</h2>

    <PostPreviewList
      :posts="services"
      postRouteName="services.service"
      buttonText="More"
    />
  </Container>
</template>

<script>
import Container from '@/components/Container'
import PostPreviewList from '@/components/PostPreviewList'

export default {
  name: 'FeaturedServices',

  props: {
    services: {
      type: Array,
      required: true
    }
  },

  components: {
    Container,
    PostPreviewList
  }
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"
@import "../stylesheets/base/typography"

.featured-services
  padding: $pad

  h2
    font-size: 24px
    margin-bottom: $pad*2

    @include media($media-gt-big)
      font-size: 36px

  ::v-deep .post-preview
    &:first-of-type
      padding-left: 0

    &:last-of-type
      padding-right: 0
</style>
