import {createApp} from './app'

// Recreate hostName string which would otherwise be provided by server
// Need this as this is in-browser entry point... this is just for convenience
// in dev... in prod, they always get server generated store.
const port = location.port ? `:${location.port}` : ''
const hostName = `${location.hostname}${port}`

const {app, router, store} = createApp({hostName})

// required to allow for async components
router.onReady(() => {
  // Load in initial vuex state
  if (window.__INITIAL_STATE__) {
    store.replaceState(window.__INITIAL_STATE__)
  }

  // this assumes App.vue template root element has `id="app"`
  app.$mount('#app')
})
