<template>
  <Container class="page-preview">
    <div class="column">
      <h2>{{ heading }}</h2>

      <div v-html="excerpt"></div>

      <Button underlined icon :to="{name: 'about'}">
        More about us
      </Button>
    </div>

    <div v-if="featuredImageVersions.length" class="column right">
      <ResponsiveImage
        :width="width"
        :image-versions="featuredImageVersions"
        :description="title"
      />
    </div>
  </Container>
</template>

<script>
import post from '@/mixins/post'

import Button from '@/components/Button'
import Container from '@/components/Container'
import ResponsiveImage from '@/components/ResponsiveImage'
import sizable from '@/mixins/sizable'

export default {
  name: 'PagePreview',

  mixins: [post, sizable],

  props: {
    post: {
      type: Object,
      required: true
    },

    headingOverride: {
      type: String,
      required: false
    }
  },

  components: {
    Button,
    Container,
    ResponsiveImage
  },

  computed: {
    heading() {
      return this.headingOverride ? this.headingOverride : this.subHeading
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.page-preview

  ::v-deep .contained
    @include media($media-gt-big)
      display: flex

    .column
      flex: 1
      padding: $pad

      @include media($media-gt-big)
        &:first-of-type
          padding-left: 0

        &:last-of-type
          padding-right: 0

    h2
      font-size: 24px
      font-family: $font-headings
      line-height: 1.6

      @include media($media-gt-big)
        font-size: 36px

    .lazy-img
      img
        height: 350px

        @include media($media-gt-big)
          height: 100%

  .right
    display: flex
    justify-content: center
    align-items: center
</style>
