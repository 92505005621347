import { render, staticRenderFns } from "./ObituariesShow.vue?vue&type=template&id=130d093e&scoped=true&"
import script from "./ObituariesShow.vue?vue&type=script&lang=js&"
export * from "./ObituariesShow.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./ObituariesShow.vue?vue&type=style&index=0&id=130d093e&lang=sass&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "130d093e",
  "5168ca95"
  
)

export default component.exports