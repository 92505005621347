<template>
  <li class="packages-item">
    <Button icon :to="route">{{ title }}</Button>
  </li>
</template>

<script>
import post from '@/mixins/post'
import Button from '@/components/Button'

export default {
  name: 'PackagesItem',

  mixins: [post],

  props: {
    post: {
      type: Object,
      required: true
    }
  },

  components: {
    Button
  },

  computed: {
    route() {
      return {name: 'packages.package', params: {slug: this.slug}}
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../../stylesheets/base/variables"
@import "../../../stylesheets/helpers/mixins"

.packages-item
  border-bottom: 1px solid $color-underlines

  .button
    font-family: $font-main-bold
    font-size: $font-size-medium
    text-transform: none
    display: flex
    justify-content: space-between
</style>
