<template>
  <Container class="affiliations">
    <ul class="affiliations-list">
      <Affiliation
        v-for="affiliation in affiliations"
        :key="affiliation.id"
        :affiliation="affiliation"
      />
    </ul>
  </Container>
</template>

<script>
import Affiliation from './Affiliation'
import Container from '@/components/Container'

export default {
  name: 'Affiliations',

  props: {
    affiliations: {
      type: Array,
      required: true
    }
  },

  components: {
    Affiliation,
    Container
  }
}
</script>

<style lang="sass" scoped>
@import "../../stylesheets/base/variables"
@import "../../stylesheets/helpers/mixins"

.affiliations
  ::v-deep .contained
    padding: $pad * 2 0
    border-top: 1px solid $color-underlines

  .affiliations-list
    @include list
    width: 100%
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: center

    @include media($media-gt-medium)
      display: flex
      flex-wrap: wrap
      justify-content: space-between

  ::v-deep .affiliation
    justify-content: center
    width: 100%
    max-width: 100%

    @include media($media-gt-medium)
      max-width: 50%
      justify-content: unset

    @include media($media-gt-big)
      max-width: 25%
</style>
