import * as types from '@/store/mutations/types'

import * as advice from '@/lib/advice'
import * as affiliations from '@/lib/affiliations'
import * as categories from '@/lib/categories'
import * as locations from '@/lib/locations'
import * as locationServices from '@/lib/location_services'
import * as menus from '@/lib/menus'
import * as packages from '@/lib/packages'
import * as pages from '@/lib/pages'
import * as products from '@/lib/products'
import * as services from '@/lib/services'
import * as tags from '@/lib/tags'
import * as testimonials from '@/lib/testimonials'
import * as messageSubjects from '@/lib/message_subjects'

export default {
  listMessageSubjects({commit}, args) {
    commit(types.REQUEST_MESSAGE_SUBJECT_LIST)

    return messageSubjects
      .listMessageSubjects(args)
      .then((response) => {
        const messageSubjects = response.data
        const readAt = Date.now()

        commit(types.RECEIVE_MESSAGE_SUBJECT_LIST, {messageSubjects, readAt})

        return response
      })
      .finally(() => {
        commit(types.REQUESTED_MESSAGE_SUBJECT_LIST)
      })
  },

  listAdvice({commit}, args) {
    commit(types.REQUEST_ADVICE_LIST)

    return advice
      .listAdvice(args)
      .then((response) => {
        const advice = response.data
        const readAt = Date.now()

        commit(types.RECEIVE_ADVICE_LIST, {advice, readAt})

        return response
      })
      .finally(() => {
        commit(types.REQUESTED_ADVICE_LIST)
      })
  },

  listFeaturedAdvice({commit}, args) {
    commit(types.REQUEST_ADVICE_LIST)

    return advice
      .listFeaturedAdvice(args)
      .then((response) => {
        const advice = response.data
        const readAt = Date.now()

        commit(types.RECEIVE_FEATURED_ADVICE_LIST, {advice, readAt})

        return response
      })
      .finally(() => {
        commit(types.REQUESTED_FEATURED_ADVICE_LIST)
      })
  },

  getAdvice({commit}, {slug}) {
    commit(types.REQUEST_ADVICE)

    return advice
      .getAdvice(slug)
      .then((advice) => {
        let readAt = Date.now()
        commit(types.RECEIVE_ADVICE, {advice, readAt, slug})
      })
      .finally(() => {
        commit(types.REQUESTED_ADVICE)
      })
  },

  listAffiliations({commit}, args) {
    commit(types.REQUEST_AFFILIATION_LIST)

    return affiliations
      .listAffiliations(args)
      .then((response) => {
        const affiliations = response.data
        const readAt = Date.now()

        commit(types.RECEIVE_AFFILIATION_LIST, {affiliations, readAt})

        return response
      })
      .finally(() => {
        commit(types.REQUESTED_AFFILIATION_LIST)
      })
  },

  listPackages({commit}, args) {
    commit(types.REQUEST_PACKAGE_LIST)

    return packages
      .listPackages(args)
      .then((response) => {
        const packages = response.data
        const readAt = Date.now()

        commit(types.RECEIVE_PACKAGE_LIST, {packages, readAt})

        return response
      })
      .finally(() => {
        commit(types.REQUESTED_PACKAGE_LIST)
      })
  },

  getPackage({commit}, {slug}) {
    commit(types.REQUEST_PACKAGE)

    return packages
      .getPackage({slug})
      .then((pack) => {
        let readAt = Date.now()
        commit(types.RECEIVE_PACKAGE, {pack, readAt, slug})
      })
      .finally(() => {
        commit(types.REQUESTED_PACKAGE)
      })
  },

  getMenu({commit}, {slug}) {
    commit(types.REQUEST_MENU)

    return menus
      .getMenu({slug})
      .then((menu) => {
        let readAt = Date.now()
        commit(types.RECEIVE_MENU, {menu, readAt, slug})
      })
      .finally(() => {
        commit(types.REQUESTED_MENU)
      })
  },

  getPage({commit}, {slug}) {
    commit(types.REQUEST_PAGE)

    return pages
      .getPage({slug})
      .then((page) => {
        let readAt = Date.now()
        commit(types.RECEIVE_PAGE, {page, readAt, slug})
      })
      .finally(() => {
        commit(types.REQUESTED_PAGE)
      })
  },

  listServices({commit}, args) {
    commit(types.REQUEST_SERVICE_LIST)

    return services
      .listServices(args)
      .then((response) => {
        const services = response.data
        const readAt = Date.now()

        commit(types.RECEIVE_SERVICE_LIST, {services, readAt})

        return response
      })
      .finally(() => {
        commit(types.REQUESTED_SERVICE_LIST)
      })
  },

  listFeaturedServices({commit}, args) {
    commit(types.REQUEST_FEATURED_SERVICE_LIST)

    return services
      .listFeaturedServices(args)
      .then((response) => {
        const services = response.data
        const readAt = Date.now()

        commit(types.RECEIVE_FEATURED_SERVICE_LIST, {services, readAt})

        return response
      })
      .finally(() => {
        commit(types.REQUESTED_FEATURED_SERVICE_LIST)
      })
  },

  getService({commit}, {slug}) {
    commit(types.REQUEST_SERVICE)

    return services
      .getService(slug)
      .then((service) => {
        let readAt = Date.now()
        commit(types.RECEIVE_SERVICE, {service, readAt, slug})
      })
      .finally(() => {
        commit(types.REQUESTED_SERVICE)
      })
  },

  listLocationServices({commit}, args) {
    commit(types.REQUEST_LOCATION_SERVICE_LIST)

    return locationServices
      .listLocationServices(args)
      .then((response) => {
        const services = response.data
        const readAt = Date.now()

        commit(types.RECEIVE_LOCATION_SERVICE_LIST, {services, readAt})

        return response
      })
      .finally(() => {
        commit(types.REQUESTED_LOCATION_SERVICE_LIST)
      })
  },

  listTags({commit}, args) {
    commit(types.REQUEST_TAG_LIST)

    return tags
      .listTags(args)
      .then((response) => {
        const tags = response.data
        const readAt = Date.now()

        commit(types.RECEIVE_TAG_LIST, {tags, readAt})

        return response
      })
      .finally(() => {
        commit(types.REQUESTED_TAG_LIST)
      })
  },

  listTestimonials({commit}, args) {
    commit(types.REQUEST_TESTIMONIAL_LIST)

    return testimonials
      .listTestimonials(args)
      .then((response) => {
        const testimonials = response.data
        const readAt = Date.now()

        commit(types.RECEIVE_TESTIMONIAL_LIST, {testimonials, readAt})

        return response
      })
      .finally(() => {
        commit(types.REQUESTED_TESTIMONIAL_LIST)
      })
  },

  listLocations({commit}, args) {
    commit(types.REQUEST_LOCATION_LIST)

    return locations
      .listLocations(args)
      .then((response) => {
        const locations = response.data
        const readAt = Date.now()

        commit(types.RECEIVE_LOCATION_LIST, {locations, readAt})

        return response
      })
      .finally(() => {
        commit(types.REQUESTED_LOCATION_LIST)
      })
  },

  listCategories({commit}, args) {
    commit(types.REQUEST_CATEGORY_LIST)

    return categories
      .listCategories(args)
      .then((response) => {
        const categories = response.data
        const readAt = Date.now()

        commit(types.RECEIVE_CATEGORY_LIST, {categories, readAt})

        return response
      })
      .finally(() => {
        commit(types.REQUESTED_CATEGORY_LIST)
      })
  },

  getProduct({commit}, {slug}) {
    commit(types.REQUEST_PRODUCT)

    return products
      .getProduct(slug)
      .then((product) => {
        let readAt = Date.now()
        commit(types.RECEIVE_PRODUCT, {product, readAt, slug})
      })
      .finally(() => {
        commit(types.REQUESTED_PRODUCT)
      })
  },

  listProducts({commit}, args) {
    commit(types.REQUEST_PRODUCT_LIST)

    return products
      .listCategoryProducts(args.categoryId, args)
      .then((response) => {
        const products = response.data
        const readAt = Date.now()

        commit(types.RECEIVE_PRODUCT_LIST, {products, readAt})

        return response
      })
      .finally(() => {
        commit(types.REQUESTED_PRODUCT_LIST)
      })
  }
}
