<template>
  <div>
    <slot v-if="!loading" :post="post" />
    <div class="loading" v-else>Loading...</div>
  </div>
</template>

<script>
import {capitalise} from '@/filters'
import dataWrapper from '@/mixins/data_wrapper'

export default {
  name: 'PostDataWrapper',

  mixins: [dataWrapper],

  props: {
    params: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },

    postType: {
      type: String,
      required: true
    },

    postTypePlural: {
      type: String,
      required: false,
      default() {
        return `${this.postType}s`
      }
    },

    slug: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      loading: false
    }
  },

  computed: {
    postTypeCapitalised() {
      return capitalise(this.postType)
    },

    getActionName() {
      return `get${this.postTypeCapitalised}`
    },

    listName() {
      return `${this.postTypePlural}BySlug`
    },

    readAt() {
      return this.resourceReadAt(this.post)
    },

    shouldGetPost() {
      return this.resourceStale(this.readAt)
    },

    post() {
      return this.$store.state[this.listName][this.slug] || {}
    }
  },

  methods: {
    getData() {
      const slug = this.slug
      const args = this.params
      this.loading = true

      return this.$store
        .dispatch(this.getActionName, {slug, args})
        .finally(() => {
          this.loading = false
        })
    },

    updateData() {
      if (this.shouldGetPost) {
        this.getData()
      }
    }
  },

  serverPrefetch() {
    return this.getData()
  },

  mounted() {
    this.updateData()
  },

  watch: {
    $route() {
      this.updateData()
    },

    params() {
      this.getData()
    }
  }
}
</script>
