<template>
  <div class="map">
    <a v-if="linkToMaps" :href="href" target="_blank">
      <LazyImg :url="src" :description="title" />
    </a>

    <LazyImg v-else :url="src" :description="title" />
  </div>
</template>

<script>
import LazyImg from '@/components/LazyImg'

const REGISTRY_OFFICE_LOCATION = '51.293576,-0.3294085'

export default {
  name: 'Map',

  props: {
    title: {
      type: String,
      required: true
    },

    center: {
      type: String,
      required: true
    },

    linkToMaps: {
      type: Boolean,
      default() {
        return true
      },
      required: false
    },

    locations: {
      type: Array,
      default() {
        return []
      },
      required: false
    },

    zoom: {
      default() {
        return 15
      },
      required: false,
      type: [Number]
    }
  },

  components: {
    LazyImg
  },

  data() {
    return {
      key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      size: '1920x350',
      type: 'roadmap',
      scale: 2
    }
  },

  computed: {
    markers() {
      let pins = this.locations.map((l) => {
        return `&markers=anchor:center|icon:${this.markerURL}|${l}`
      })

      pins.push(`&markers=${this.registryOfficeMarker}`)

      return pins.join('')
    },

    hostname() {
      return process.env[`VUE_APP_API_HOST_CLIENT`]
    },

    markerURL() {
      return `${this.hostname}/map-pin.png`
    },

    registryOfficeMarkerURL() {
      return `${this.hostname}/registry-office.png`
    },

    registryOfficeMarker() {
      return `anchor:left|icon:${this.registryOfficeMarkerURL}|${REGISTRY_OFFICE_LOCATION}`
    },

    href() {
      const loc = this.locations[0]

      return `https://www.google.co.uk/maps/place/${loc}/@${this.center}`
    },

    src() {
      // TODO: extract style
      return `https://maps.googleapis.com/maps/api/staticmap?key=${this.key}&center=${this.center}&zoom=${this.zoom}&format=jpg&maptype=${this.type}&style=element:geometry%7Ccolor:0xf5f5f5&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x616161&style=element:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:administrative.land_parcel%7Celement:labels%7Cvisibility:off&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:poi%7Celement:labels.text%7Cvisibility:off&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:road.local%7Celement:labels%7Cvisibility:off&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&size=${this.size}&scale=${this.scale}${this.markers}`
    }
  }
}
</script>

<style lang="sass" scoped>
.map
</style>
