/**
 * Page mixin for view components
 */
import Page from '@/components/Page'
import PostDataWrapper from '@/components/PostDataWrapper'

export default {
  components: {
    Page,
    PostDataWrapper
  }
}
