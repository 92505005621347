<template>
  <li class="top-advice-list-item">
    <div class="description">
      <div class="title">{{ title }}</div>

      <p>{{ subHeading }}</p>
    </div>

    <Button icon :to="route" :aria-label="title">
      <span class="read-more">Read more</span>
    </Button>
  </li>
</template>

<script>
import post from '@/mixins/post'

import Button from '@/components/Button'

export default {
  name: 'TopAdviceListItem',

  mixins: [post],

  props: {
    post: {
      type: Object,
      required: true
    }
  },

  components: {
    Button
  },

  computed: {
    route() {
      const name = 'advice.advice'
      const params = {slug: this.slug}

      return {name, params}
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../stylesheets/base/variables"
@import "../../stylesheets/helpers/mixins"

.top-advice-list-item
  padding: $pad * 2 $pad
  flex: 1
  border-bottom: 1px solid $color-underlines
  display: flex
  justify-content: space-between

  &:first-of-type
    padding-top: $pad

  &:last-of-type
    border-bottom: none
    padding-bottom: $pad

  @include media($media-gt-big)
    padding: $pad
    display: inline-block
    border-bottom: none

  .title
    font-family: $font-headings
    margin: 0
    font-size: 18px

    @include media($media-gt-big)
      font-size: 24px

  p
    font-family: $font-main
    font-size: 14px
    margin-bottom: 0

    @include media($media-gt-big)
      font-size: 16px
      margin: 16px 0

  .read-more
    display: none

    @include media($media-gt-big)
      display: inline-block

  .button
    padding: $pad

    @include media($media-gt-big)
      padding: 0
</style>
