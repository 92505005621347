<template>
  <ul class="post-preview-list">
    <PostPreview
      v-for="post in posts"
      :key="post.id"
      :post="post"
      :postRouteName="postRouteName"
      :buttonText="postTitle(post)"
      :buttonUnderlined="buttonUnderlined"
      :route="route"
    />
  </ul>
</template>

<script>
import PostPreview from '@/components/PostPreview'

export default {
  name: 'PostPreviewList',

  props: {
    posts: {
      type: Array,
      required: true
    },

    postRouteName: {
      type: String,
      required: false
    },

    buttonText: {
      type: String,
      required: false
    },

    buttonUnderlined: {
      type: Boolean,
      default() {
        return false
      }
    },

    route: {
      type: Function,
      required: false
    }
  },

  components: {
    PostPreview
  },

  methods: {
    postTitle(post) {
      return post.title ? post.title.rendered : post.name
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"
@import "../stylesheets/base/typography"

.post-preview-list
  @include list

  @include media($media-gt-big)
    display: flex
    flex-wrap: wrap

  .post-preview
    padding: 0 0 $pad*2 0
    font-size: 14px

    .column
      width: 100%

    @include media($media-gt-big)
      font-size: 16px
      padding: $pad

    ::v-deep h3
      font-size: 18px

      @include media($media-gt-big)
        font-size: 24px

    ::v-deep .left
      padding-bottom: $pad

      @inclide media($media-gt-big)
        padding-bottom: $pad * 2

    @include media($media-gt-big)
      flex: 1
      flex-direction: row
</style>
