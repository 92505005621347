<template>
  <li class="affiliation">
    <a :href="url" target="_blank">
      <LazyImg
        class="affiliation-logo"
        :url="featuredImageUrl"
        :description="title"
      />

      <p class="affiliation-name">{{ title }}</p>
    </a>
  </li>
</template>

<script>
import post from '@/mixins/post'

import LazyImg from '@/components/LazyImg'

export default {
  name: 'Affiliation',

  mixins: [post],

  props: {
    affiliation: {
      type: Object,
      required: true
    }
  },

  components: {
    LazyImg
  },

  computed: {
    post() {
      return this.affiliation
    },

    url() {
      return this.acf.url
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../stylesheets/base/variables"
@import "../../stylesheets/helpers/mixins"

.affiliation
  padding-bottom: $pad

  &:last-child
    padding-bottom: 0

  a
    display: flex
    align-items: center
    text-decoration: none
    color: $color-dark
    padding: $pad
    @include row

  @include media($media-gt-big)
    margin: 0
    padding: 0

  .affiliation-logo
    width: 100%
    max-width: 70px
    margin-right: $pad


  .affiliation-name
    font-family: $font-main
    font-size: $font-size-small

  @include media($media-gt-medium)
    flex-basis: 50%

  @include media($media-gt-big)
    flex-basis: 25%
</style>
