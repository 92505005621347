<template>
  <li class="breadcrumb">
    <RouterLink :to="{name: 'catalogue.category', params: {slug: slug}}">
      {{ title }}
    </RouterLink>
  </li>
</template>

<script>
import post from '@/mixins/post'

export default {
  name: 'Breadcrumb',

  mixins: [post],

  props: {
    post: {
      type: Object,
      required: true
    },

    active: {
      type: Boolean,
      required: false,
      default: true
    }
  }
}
</script>

<style lang="sass" scoped>
.breadcrumb
  display: inline-block
</style>
