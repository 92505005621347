<template>
  <Fragment>
    <slot v-if="!loading" :posts="posts" />
    <div class="loading" v-else>Loading...</div>
  </Fragment>
</template>

<script>
import dataWrapper from '@/mixins/data_wrapper'
import {capitalise} from '@/filters'

const INITIAL_PAGES = 0
const TOTAL_PAGES_HEADER = 'x-wp-totalpages'

export default {
  name: 'PostsDataWrapper',

  mixins: [dataWrapper],

  props: {
    postType: {
      type: String,
      required: true
    },

    postTypePlural: {
      type: String,
      required: false,
      default() {
        return `${this.postType}s`
      }
    },

    params: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      totalPages: INITIAL_PAGES,
      loading: false
    }
  },

  computed: {
    currentPage() {
      return this.params.page
    },

    postTypeCapitalised() {
      return capitalise(this.postType)
    },

    postTypePluralCapitalised() {
      return capitalise(this.postTypePlural)
    },

    listActionName() {
      return `list${this.postTypePluralCapitalised}`
    },

    listName() {
      return `${this.postType}List`
    },

    listedAtKey() {
      return `${this.postTypePlural}ListedAt`
    },

    listedAt() {
      return this.$store.state[this.listedAtKey]
    },

    shouldListPosts() {
      return true
      //return this.resourceStale(this.listedAt)
    },

    posts() {
      return this.$store.state[this.listName]
    }
  },

  methods: {
    getData() {
      this.loading = true

      return this.$store
        .dispatch(this.listActionName, this.params)
        .then((response) => {
          const totalPages = parseInt(response.headers[TOTAL_PAGES_HEADER])
          this.totalPages = totalPages

          // Send total_pages back up the tree
          this.$emit('loaded', {totalPages})

          return response
        })
        .finally(() => {
          this.loading = false
        })
    },

    updateData() {
      if (this.shouldListPosts) {
        return this.getData()
      }
    }
  },

  serverPrefetch() {
    return this.getData()
  },

  mounted() {
    return this.updateData()
  },

  watch: {
    $route() {
      this.updateData()
    },

    params() {
      this.getData()
    }
  }
}
</script>

<style lang="sass" scoped>
.posts-data-wrapper
</style>
