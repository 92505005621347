<template>
  <footer class="footer">
    <PostsDataWrapper
      class="testimonials"
      postType="testimonial"
      :params="{per_page: 2, page: 1}"
    >
      <template v-slot="slotProps">
        <Testimonials class="testimonials" :testimonials="slotProps.posts" />
      </template>
    </PostsDataWrapper>

    <PostDataWrapper class="cta" postType="page" slug="footer">
      <template v-slot="slotProps">
        <CTA class="cta" :post="slotProps.post" />
      </template>
    </PostDataWrapper>

    <PostsDataWrapper
      class="affiliations"
      postType="affiliation"
      :params="{per_page: 4, page: 1}"
    >
      <template v-slot="slotProps">
        <Affiliations class="affiliations" :affiliations="slotProps.posts" />
      </template>
    </PostsDataWrapper>

    <Bottom />
  </footer>
</template>

<script>
import Affiliations from './Affiliations'
import Bottom from './Bottom'
import CTA from './CTA'
import Testimonials from './Testimonials'

import PostDataWrapper from '@/components/PostDataWrapper'
import PostsDataWrapper from '@/components/PostsDataWrapper'

export default {
  name: 'Footer',

  components: {
    Affiliations,
    Bottom,
    CTA,
    Testimonials,
    PostDataWrapper,
    PostsDataWrapper
  }
}
</script>

<style lang="sass" scoped>
@import "../../stylesheets/base/variables"
@import "../../stylesheets/helpers/mixins"

.footer
</style>
