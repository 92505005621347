<template>
  <div class="page-obituary">
    <PageContainer :banner="false">
      <ObituariesShow :id="id" />
    </PageContainer>
  </div>
</template>

<script>
import page from '@/mixins/page'

import ObituariesShow from '@/components/obituaries/ObituariesShow'
import PageContainer from '@/components/PageContainer'

export default {
  name: 'Obituary',

  mixins: [page],

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  components: {
    PageContainer,
    ObituariesShow
  }
}
</script>

<style lang="sass" scoped>
.page-obituary
</style>
