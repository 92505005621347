<template>
  <div class="page-catalogue-category">
    <CategoryDataWrapper :slug="slug" :params="{per_page: 100, page: 1}">
      <template v-slot="categoryProps">
        <Page :page="categoryProps.category">
          <template v-slot="pageProps">
            <Banner
              :image-versions="[{width_px: 100, url: pageProps.acf.image}]"
            >
              <h1>{{ pageProps.title }}</h1>
              <p>{{ pageProps.subHeading }}</p>
            </Banner>

            <PageContainer>
              <Breadcrumbs
                v-if="hasCategory(categoryProps.category)"
                :categories="categoryProps.categories"
                :category="categoryProps.category"
              />

              <h1>{{ pageProps.title }}</h1>

              <div v-html="categoryProps.category.description"></div>

              <div class="columns-container">
                <aside
                  class="sub-categories"
                  v-if="categoryProps.subCategories.length"
                >
                  <div class="categories">
                    <PostPreviewList
                      :posts="categoryProps.subCategories"
                      postRouteName="catalogue.category"
                      buttonText="More"
                    />
                  </div>
                </aside>

                <div v-else class="products">
                  <PostsDataWrapper
                    v-if="categoryProps.category.id"
                    postType="product"
                    :params="productParams(categoryProps.category)"
                    :store-key="categoryProps.category.slug"
                    @loaded="postsLoaded"
                  >
                    <template v-slot="productsProps">
                      <PostPreviewList
                        :posts="productsProps.posts"
                        :route="
                          function(product) {
                            return {
                              name: 'catalogue.category.product',
                              params: {
                                categorySlug: slug,
                                slug: product.slug
                              }
                            }
                          }
                        "
                        buttonText="More"
                      />

                      <Pagination v-model="pagination" />
                    </template>
                  </PostsDataWrapper>
                </div>
              </div>
            </PageContainer>
          </template>
        </Page>
      </template>
    </CategoryDataWrapper>
  </div>
</template>

<script>
import page from '@/mixins/page'
import pagination from '@/mixins/pagination'

import Banner from '@/components/Banner'
import Breadcrumbs from '@/components/catalogue/Breadcrumbs'
import CategoryDataWrapper from '@/components/CategoryDataWrapper'
import Pagination from '@/components/Pagination'
import PageContainer from '@/components/PageContainer'
import PostsDataWrapper from '@/components/PostsDataWrapper'
import PostPreviewList from '@/components/PostPreviewList'

export default {
  name: 'CatalogueCategory',

  mixins: [page, pagination],

  props: {
    slug: {
      required: true,
      type: String
    }
  },

  components: {
    Banner,
    Breadcrumbs,
    CategoryDataWrapper,
    PageContainer,
    Pagination,
    PostsDataWrapper,
    PostPreviewList
  },

  data() {
    return {
      pagination: {}
    }
  },

  methods: {
    productParams(category) {
      const categoryId = category.id
      return {...this.pagination, categoryId}
    },

    /**
     * Gross but prevents weird serverside crash
     */
    hasCategory(category) {
      return category && Object.keys(category).includes('parent')
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../stylesheets/base/variables"
@import "../../stylesheets/helpers/mixins"

.page-catalogue-category
  margin-top: 100px

  .columns-container
    padding: $pad 0

    @include media($media-gt-big)
      display: flex

    .sub-categories
      padding: $pad $pad $pad 0
      width: 100%


      h3
        font-size: 24px
        padding-bottom: $pad
        border-bottom: 2px solid $color-underlines
        margin: 0

      .category-list
        @include list


      ::v-deep .post-preview
        @include media($media-gt-big)
          flex: none
          width: 50%
          padding: $pad

          &:nth-of-type(3n -2)
            padding-left: 0

          &:nth-of-type(3n)
            padding-right: 0

      @include media($media-gt-big)

    .products
      padding: $pad 0
      width: 100%

      ::v-deep .post-preview
        @include media($media-gt-big)
          flex: none
          width: 50%
          padding: $pad

          &:nth-of-type(3n -2)
            padding-left: 0

          &:nth-of-type(3n)
            padding-right: 0
</style>
