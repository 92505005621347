<template>
  <aside class="sidebar">
    <RelatedAdvice class="row" :advice="advice" />
    <RelatedServices class="row" :title="servicesTitle" :services="services" />
    <RelatedPackages class="row" :title="packagesTitle" :packages="packages" />

    <CTA :title="ctaTitle" />
  </aside>
</template>

<script>
import CTA from './CTA'
import RelatedAdvice from './RelatedAdvice'
import RelatedPackages from './RelatedPackages'
import RelatedServices from './RelatedServices'

export default {
  name: 'Sidebar',

  props: {
    advice: {
      type: Array,
      required: true
    },

    packages: {
      type: Array,
      required: true
    },

    services: {
      type: Array,
      required: true
    },

    ctaTitle: {
      type: String,
      required: false
    },

    packagesTitle: {
      type: String,
      required: false
    },

    servicesTitle: {
      type: String,
      required: false
    }
  },

  components: {
    CTA,
    RelatedAdvice,
    RelatedPackages,
    RelatedServices
  }
}
</script>

<style lang="sass" scoped>
@import "../../stylesheets/base/variables"
@import "../../stylesheets/helpers/mixins"

.sidebar
  font-size: 12px

  @include media($media-gt-big)
    flex: 1
    padding-right: 0
    max-width: 323px
    font-size: 14px
    padding-left: $pad

  .row
    padding-bottom: $pad*2
</style>
