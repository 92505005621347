import Axios from 'axios'

/**
 * Prepend api host url to a path
 *
 * @param {string} url - a request path
 *
 * @returns {string}
 */
function prependHost(url) {
  const vueTarget = process.env.VUE_APP_TARGET
  const apiHost = process.env[`VUE_APP_API_HOST_${vueTarget.toUpperCase()}`]
  const urlBase = process.env['VUE_APP_API_PATH']

  return `${apiHost}${urlBase}${url}`
}

/**
 * Bridges to axios request, and sets some request defaults
 *
 * @param {string} method - a request method
 * @param {string} path - a request path relative to root
 * @param {object} data - request body as object
 * @param {object} params - request query params as object
 *
 * @returns {promise}
 */
export function request(method, path, data = {}, params = {}) {
  const url = prependHost(path)

  return Axios.request({
    url,
    method,
    data,
    params
  })
}

/**
 * Bridge to `request` to make get requests with ease
 *
 * We mostly make get requests, they dont need all the options that are required
 * by `request`.
 *
 * @param {string} path - a request path relative to root
 * @param {object} params - request query params as object
 *
 * @returns {promise}
 */
export function get(path, params = {}) {
  return request('get', path, {}, params)
}
