<template>
  <li class="services-item">
    <Button icon :to="route">{{ title }}</Button>
  </li>
</template>

<script>
import post from '@/mixins/post'
import Button from '@/components/Button'

export default {
  name: 'ServicesItem',

  mixins: [post],

  props: {
    post: {
      type: Object,
      required: true
    }
  },

  components: {
    Button
  },

  computed: {
    route() {
      return {name: 'services.service', params: {slug: this.slug}}
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../../stylesheets/base/variables"
@import "../../../stylesheets/helpers/mixins"

.services-item
  .button
    text-transform: none
</style>
