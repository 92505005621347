<template>
  <div v-if="hasPackages" class="related-packages">
    <h3>{{ title }} packages</h3>

    <ul class="related-packages-list">
      <PostDataWrapper
        v-for="article in packages"
        :key="article.ID"
        postType="package"
        postTypePlural="packages"
        :slug="article.post_name"
      >
        <template v-slot:default="slotProps">
          <PackagesItem :post="slotProps.post" />
        </template>
      </PostDataWrapper>
    </ul>
  </div>
</template>

<script>
import PackagesItem from './PackagesItem'
import PostDataWrapper from '@/components/PostDataWrapper'

export default {
  name: 'RelatedPackages',

  props: {
    packages: {
      type: Array,
      required: true
    },

    title: {
      required: false,
      default() {
        return 'Related'
      }
    }
  },

  components: {
    PackagesItem,
    PostDataWrapper
  },

  computed: {
    hasPackages() {
      return this.packages.length
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../../stylesheets/base/variables"
@import "../../../stylesheets/helpers/mixins"

.related-packages
  h3
    font-size: 24px
    padding-bottom: $pad
    border-bottom: 2px solid $color-underlines
    margin: 0

  .related-packages-list
    @include list
</style>
