<template>
  <div class="loader" :class="{'is-loading': loading}">
    <div class="bar"></div>
  </div>
</template>

<script>
export default {
  name: 'Loader',

  props: {
    loading: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../stylesheets/base/_variables'
@import "../stylesheets/helpers/mixins"

$bar-width: 300px
$bar-height: 3px
$animation-duration: 3s

.loader
  height: $bar-height
  width: 100%
  position: fixed
  overflow: hidden
  background: transparent
  z-index: 999ing9

  @include media($media-gt-big)
    position: relative

  &.is-loading
    .bar
      transform: translateX(-$bar-width)
      width: $bar-width
      height: 100%
      animation: loading $animation-duration ease-in-out infinite
      background: $color-highlight

@keyframes loading
    from
      transform: translateX(-$bar-width)
      width: $bar-width
    to
      width: 300%
      transform: translateX(100%)
</style>
