var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-catalogue-category"},[_c('CategoryDataWrapper',{attrs:{"slug":_vm.slug,"params":{per_page: 100, page: 1}},scopedSlots:_vm._u([{key:"default",fn:function(categoryProps){return [_c('Page',{attrs:{"page":categoryProps.category},scopedSlots:_vm._u([{key:"default",fn:function(pageProps){return [_c('Banner',{attrs:{"image-versions":[{width_px: 100, url: pageProps.acf.image}]}},[_c('h1',[_vm._v(_vm._s(pageProps.title))]),_c('p',[_vm._v(_vm._s(pageProps.subHeading))])]),_c('PageContainer',[(_vm.hasCategory(categoryProps.category))?_c('Breadcrumbs',{attrs:{"categories":categoryProps.categories,"category":categoryProps.category}}):_vm._e(),_c('h1',[_vm._v(_vm._s(pageProps.title))]),_c('div',{domProps:{"innerHTML":_vm._s(categoryProps.category.description)}}),_c('div',{staticClass:"columns-container"},[(categoryProps.subCategories.length)?_c('aside',{staticClass:"sub-categories"},[_c('div',{staticClass:"categories"},[_c('PostPreviewList',{attrs:{"posts":categoryProps.subCategories,"postRouteName":"catalogue.category","buttonText":"More"}})],1)]):_c('div',{staticClass:"products"},[(categoryProps.category.id)?_c('PostsDataWrapper',{attrs:{"postType":"product","params":_vm.productParams(categoryProps.category),"store-key":categoryProps.category.slug},on:{"loaded":_vm.postsLoaded},scopedSlots:_vm._u([{key:"default",fn:function(productsProps){return [_c('PostPreviewList',{attrs:{"posts":productsProps.posts,"route":function(product) {
                          return {
                            name: 'catalogue.category.product',
                            params: {
                              categorySlug: _vm.slug,
                              slug: product.slug
                            }
                          }
                        },"buttonText":"More"}}),_c('Pagination',{model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})]}}],null,true)}):_vm._e()],1)])],1)]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }