<template>
  <form class="contact-form" @submit.prevent="onSubmit">
    <Input
      placeholder="Name"
      v-model="resource.name"
      @input="$v.resource.name.$touch()"
      :error="nameError"
    />

    <Input
      placeholder="Email"
      v-model="resource.email"
      @input="$v.resource.email.$touch()"
      :error="emailError"
    />

    <Select
      placeholder="Subject"
      v-model="resource.subject"
      @input="$v.resource.subject.$touch()"
      :error="subjectError"
    >
      <option
        v-for="subject in subjectNames"
        :value="subject"
        :key="subject"
        :selected="subject === resource.subject"
      >
        {{ subject }}
      </option>
    </Select>

    <TextArea
      placeholder="Message"
      v-model="resource.message"
      @input="$v.resource.message.$touch()"
      :error="messageError"
    />

    <Button underlined icon>Send</Button>

    <Loader :loading="loading" />
  </form>
</template>

<script>
import form from '@/mixins/form'

import {errorMessages} from '@/lib/error_messages'
import {required, email} from 'vuelidate/lib/validators'

import Button from '@/components/forms/inputs/Button'

export default {
  name: 'ContactForm',

  mixins: [form],

  props: {
    subjects: {
      type: Array,
      required: false,
      default() {
        return []
      }
    }
  },

  components: {
    Button
  },

  computed: {
    ...errorMessages(['name', 'email', 'message', 'subject']),

    subjectNames() {
      return this.subjects.map((subject) => {
        return subject.title.rendered
      })
    }
  },

  validations: {
    resource: {
      name: {required},
      email: {required, email},
      subject: {required},
      message: {required}
    }
  }
}
</script>

<style lang="sass" scoped>
.contact-form
  max-width: 600px
  margin: 60px auto
  position: relative
</style>
