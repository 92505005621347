<template>
  <Container class="cta">
    <h3>{{ title }}</h3>

    <div v-html="content"></div>

    <Phone :collapsable="false" />

    <div class="bit">
      <Button dark icon border :to="{name: 'contact'}">
        Contact us
      </Button>
    </div>
  </Container>
</template>

<script>
import Button from '@/components/Button'
import Container from '@/components/Container'
import Phone from '@/components/Phone'

import post from '@/mixins/post'

export default {
  name: 'CTA',

  mixins: [post],

  props: {
    post: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      pageSlug: 'footer'
    }
  },

  components: {
    Button,
    Container,
    Phone
  }
}
</script>

<style lang="sass" scoped>
@import "../../stylesheets/base/variables"
@import "../../stylesheets/helpers/mixins"

.cta
  background: $color-highlight
  padding: $pad * 4 0
  color: $color-light

  ::v-deep .button
    .inner
      color: $color-light

  ::v-deep .contained
    text-align: center

  ::v-deep .phone
    justify-content: center

    .value
      color: $color-light

    .phone-icon
      fill: $color-light

  .bit
    display: inline-block
    padding-top: $pad

  .phone
    padding: $pad*2 0
</style>
