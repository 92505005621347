<template>
  <div class="page-post-type-show">
    <PostDataWrapper
      :postType="postType"
      :postTypePlural="postTypePlural"
      :slug="slug"
    >
      <template v-slot:default="slotProps">
        <Page :page="slotProps.post" />
        <Post class="main-content" :post="slotProps.post" />
      </template>
    </PostDataWrapper>
  </div>
</template>

<script>
import page from '@/mixins/page'

import PostDataWrapper from '@/components/PostDataWrapper'
import Post from '@/components/Post'

export default {
  name: 'PostTypeShow',

  mixins: [page],

  props: {
    slug: {
      type: String,
      required: true
    }
  },

  components: {
    PostDataWrapper,
    Post
  },

  computed: {
    meta() {
      return this.$route.meta
    },

    pageSlug() {
      return this.meta.pageSlug
    },

    postType() {
      return this.meta.postType
    },

    postTypePlural() {
      return this.meta.postTypePlural
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.page-post-type-show
  ::v-deep .contained
    @include media($media-gt-big)
      display: flex
</style>
