<template>
  <div class="category-data-wrapper">
    <slot
      v-if="!loading"
      :category="category"
      :categories="categoryList"
      :subCategories="subCategories"
    />

    <div class="loading" v-else>Loading...</div>
  </div>
</template>

<script>
import dataWrapper from '@/mixins/data_wrapper'

import {mapActions, mapState} from 'vuex'

export default {
  name: 'CategoryDataWrapper',

  mixins: [dataWrapper],

  props: {
    params: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },

    slug: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      loading: false
    }
  },

  computed: {
    ...mapState(['categoryList', 'categoriesListedAt']),

    /**
     * The category which we are dealing with
     *
     * @returns Object
     */
    category() {
      return (
        this.categoryList.find((category) => {
          return category.slug === this.slug
        }) || {}
      )
    },

    /**
     * Sub categories of `this.category`
     *
     * @returns Array
     */
    subCategories() {
      return this.categoryList.filter((category) => {
        return category.parent === this.category.id
      })
    },

    shouldGetData() {
      return this.resourceStale(this.categoriesListedAt)
    }
  },

  methods: {
    ...mapActions(['listCategories']),

    /**
     * Because wordpress we have to get all categories and filter out the one
     * that we are interested in
     */
    getData() {
      this.loading = true

      return this.listCategories(this.params).finally(() => {
        this.loading = false
      })
    },

    updateData() {
      if (this.shouldGetData) {
        this.getData()
      }
    }
  },

  serverPrefetch() {
    return this.getData()
  },

  mounted() {
    this.updateData()
  },

  watch: {
    $route() {
      this.updateData()
    },

    params() {
      this.getData()
    }
  }
}
</script>
