<template>
  <div class="location" itemscope itemtype="http://schema.org/LocalBusiness">
    <section>
      <h2 itemprop="name">
        <span class="hid">Hawkins and Sons Ltd, -</span>
        {{ title }}
      </h2>

      <div itemprop="image">
        <lazy-img
          class="location-image"
          :url="featuredImageUrl"
          :description="`Hawkins and Sons Ltd. ${title}`"
        />

        <meta itemprop="url" :value="featuredImageUrl" />
      </div>

      <div itemprop="description" v-html="content"></div>
    </section>

    <section>
      <h3>Services</h3>

      <ul class="services">
        <li v-for="service in locationServices" :key="service.slug">
          {{ service.name }}
        </li>
      </ul>
    </section>

    <section>
      <h3>Location</h3>

      <Map
        :center="mapCenter"
        :locations="[latLng]"
        :title="title"
        :zoom="17"
      />

      <p
        class="address"
        itemprop="address"
        itemscope
        itemtype="http://schema.org/PostalAddress"
      >
        <span itemprop="streetAddress">{{ address.streetAddress }}</span>
        <span itemprop="addressLocality">{{ address.locality }}</span>
        <span itemprop="addressRegion">{{ address.region }}</span>
        <span itemprop="postalCode">{{ address.postCode }}</span>
        <span itemprop="addressCountry">{{ address.country }}</span>
      </p>
    </section>

    <section>
      <h3>Office opening hours</h3>

      <div v-for="hours in openingHours" :key="hours.datetime">
        <time itemprop="openingHours" :datetime="hours.datetime">
          {{ hours.value }}
        </time>
      </div>

      <p>
        We are also available 24 hours a day by phone for emergency call outs.
      </p>
    </section>
  </div>
</template>

<script>
import LazyImg from '@/components/LazyImg'
import Map from '@/components/Map'

import post from '@/mixins/post'
const DAYS = {
  mo: 'Monday',
  tu: 'Tuesday',
  we: 'Wednesday',
  th: 'Thursday',
  fr: 'Friday',
  sa: 'Saturday',
  su: 'Sunday'
}

function niceDay(short) {
  return DAYS[short.toLowerCase()]
}

export default {
  name: 'Location',

  mixins: [post],

  props: {
    location: {
      required: true,
      type: Object
    },

    services: {
      required: true,
      type: Array
    }
  },

  components: {
    LazyImg,
    Map
  },

  computed: {
    post() {
      return this.location
    },

    mapCenter() {
      return this.acf.map_center
    },

    latLng() {
      return this.acf.latitude_longitude
    },

    address() {
      const acf = this.acf

      const streetAddress = acf.street_address
      const locality = acf.locality
      const region = acf.region
      const postCode = acf.post_code
      const country = acf.country

      return {streetAddress, locality, region, postCode, country}
    },

    openingHours() {
      const hours = this.acf.opening_hours

      const hoursList = hours.split('||').map((times) => {
        const datetime = times
        const split = times.split(' ')
        const daysString = split[0]
        const time = split[1]
        const days = daysString.split(',')

        let niceDays = []

        if (days.length === 1) {
          niceDays = niceDay(days[0])
        } else {
          niceDays = `${niceDay(days[0])} - ${niceDay(days[days.length - 1])}`
        }

        const value = `${niceDays} ${time}`

        return {
          datetime,
          value
        }
      })

      return hoursList
    },

    locationServices() {
      const ids = this.post.location_services

      return ids.map((id) => {
        return this.services.find((service) => {
          return service.id === id
        })
      })
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.location
  .location-image
    max-width: 100%

  section
    padding-bottom: $pad

    @include media($media-gt-big)
      padding-bottom: $pad*2

    &:last-of-type
      padding-bottom: 0

  .hid
    display: none

  .address
    > span
      display: block

  ::v-deep .lazy-img
    img
      max-height: 350px

      @include media($media-gt-big)
        max-height: 100%
</style>
