<template>
  <div class="pagination">
    <div class="col">
      <p class="location">Page {{ currentPage }} of {{ pages }}</p>

      <Button
        :disabled="currentPage == 1"
        @click="prevPage"
        v-if="pages > 1 && currentPage > 1"
      >
        <div class="button-inner">
          <Sprite name="left-arrow" />
        </div>
      </Button>

      <Button
        :disabled="currentPage == pages"
        @click="nextPage"
        v-if="pages > 1 && currentPage < pages"
      >
        <div class="button-inner">
          <Sprite name="right-arrow" />
        </div>
      </Button>
    </div>

    <div class="col">
      <label class="per-page-label">
        <span class="label-text">Per page: </span>

        <select v-model="perPage">
          <option v-for="option in options" :key="option" :value="option">
            {{ option }}
          </option>
        </select>
      </label>
    </div>
  </div>
</template>

<script>
import Button from '@/components/forms/inputs/Button'
import Sprite from '@/components/Sprite'

export default {
  name: 'Pagination',

  props: {
    value: {
      type: Object,
      required: true
    }
  },

  components: {
    Button,
    Sprite
  },

  data() {
    return {
      options: [1, 3, 5, 10, 25]
    }
  },

  computed: {
    perPage: {
      get() {
        return this.value.per_page
      },

      set(perPage) {
        const pagination = {...this.value, page: 1, per_page: perPage}
        this.$emit('input', pagination)
      }
    },

    pages() {
      return this.value.total_pages
    },

    currentPage() {
      return this.value.page
    }
  },

  methods: {
    changePage(page) {
      const pagination = {...this.value, page}
      this.$emit('input', pagination)
    },

    prevPage() {
      return this.changePage(Math.max(0, this.currentPage - 1))
    },

    nextPage() {
      return this.changePage(Math.min(this.pages, this.currentPage + 1))
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.pagination
  padding: $pad
  display: flex
  align-items: center
  justify-content: space-between

  .col
    display: flex
    align-items: center

  .location
    padding-right: $pad

  .button
    padding-right: $pad

  .button-inner
    display: flex
    align-items: center

  .label-text
    padding-right: $pad
</style>
