import {get} from '@/lib/request'

function packageIndexPath() {
  return `/wp/v2/packages`
}

function packagePath(slug) {
  return `${packageIndexPath()}/?slug=${slug}&_embed`
}

export function listPackages(params) {
  return get(`${packageIndexPath()}?_embed`, params)
}

export function getPackage({slug}) {
  return get(packagePath(slug)).then(({data}) => {
    return data[0]
  })
}
