<template>
  <div class="page-catalogue">
    <PostDataWrapper postType="page" slug="catalogue">
      <template v-slot="slotProps">
        <Page :page="slotProps.post">
          <template v-slot="pageProps">
            <Banner :image-versions="pageProps.featuredImageVersions">
              <h1>Catalogue</h1>
              <p>{{ pageProps.subHeading }}</p>
            </Banner>

            <PageContainer :banner="false">
              <div v-html="pageProps.content"></div>

              <PostsDataWrapper
                postType="category"
                postTypePlural="categories"
                :params="{per_page: 100, page: 1}"
              >
                <template v-slot="categoryProps">
                  <Categories :parentId="0" :categories="categoryProps.posts" />
                </template>
              </PostsDataWrapper>
            </PageContainer>
          </template>
        </Page>
      </template>
    </PostDataWrapper>
  </div>
</template>

<script>
import page from '@/mixins/page'

import Banner from '@/components/Banner'
import Categories from '@/components/catalogue/Categories'
import PageContainer from '@/components/PageContainer'
import PostsDataWrapper from '@/components/PostsDataWrapper'

export default {
  name: 'Catalogue',

  mixins: [page],

  components: {
    Banner,
    Categories,
    PageContainer,
    PostsDataWrapper
  }
}
</script>

<style lang="sass" scoped>
.page-catalogue
</style>
