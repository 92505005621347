import {request} from '@/lib/request'

function pagePath(slug) {
  return `/wp/v2/pages/?slug=${slug}&_embed`
}

export function getPage({slug}) {
  return request('get', pagePath(slug)).then(({data}) => {
    return data[0]
  })
}
