<template>
  <div class="contact">
    <div class="phone-wrapper">
      <Phone />
    </div>

    <div>
      <RouterLink :to="{name: 'contact'}" class="btn">Contact us</RouterLink>
    </div>
  </div>
</template>

<script>
import Phone from '@/components/Phone'

export default {
  name: 'Contact',

  components: {
    Phone
  }
}
</script>

<style lang="sass" scoped>
@import "../../stylesheets/base/variables"
@import "../../stylesheets/helpers/mixins"

.contact
  display: flex
  align-items: center

  .phone
    ::v-deep
      flex-direction: column
      align-items: end

      .spacer
        display: none!important

  >div
    padding-right: $pad * 2

    @include media($media-gt-big)
      padding-right: $pad

    &:last-of-type
      padding-right: 0

  .btn
    background: $color-highlight
    color: $color-light
    text-decoration: none
    display: none
    cursor: pointer
    text-transform: uppercase
    font-size: $font-size-small
    letter-spacing: 1.2px
    font-family: $font-main
    padding: $pad / 2

    @media print
      padding: $pad / 2!important
      font-size: 10px!important

    @include media($media-gt-big)
      display: inline-block
      transition: padding $transition-duration $transition-easing, font-size $transition-duration $transition-easing

      .is-scrolling &
        transition-delay: $transition-delay
        padding: $pad / 2
        font-size: 10px

    @include media($media-gt-very-big)
      padding: $pad
</style>
