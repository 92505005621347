<template>
  <nav class="categories">
    <PostPreviewList
      :posts="filteredCategories"
      postRouteName="catalogue.category"
      buttonText="More"
    />
  </nav>
</template>

<script>
const CATEGORY_BLACKLIST = ['Uncategorized']

import PostPreviewList from '@/components/PostPreviewList'

export default {
  name: 'Categories',

  props: {
    categories: {
      type: Array,
      default() {
        return []
      }
    },

    parentId: {
      type: Number,
      required: true
    }
  },

  components: {
    PostPreviewList
  },

  computed: {
    filteredCategories() {
      return this.categories
        .filter((category) => {
          return !CATEGORY_BLACKLIST.includes(category.name)
        })
        .filter((category) => {
          return category.parent === this.parentId
        })
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../stylesheets/base/variables"
@import "../../stylesheets/helpers/mixins"

.categories
  ::v-deep .post-preview
    @include media($media-gt-big)
      flex: none
      width: 50%
      padding: $pad

      &:nth-of-type(3n -2)
        padding-left: 0

      &:nth-of-type(3n)
        padding-right: 0
</style>
