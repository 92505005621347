<template>
  <div class="embed">
    <div
      class="fds-container"
      :ref="elementRef"
      :data-fds-tenant="tennant"
    ></div>
  </div>
</template>

<script>
const SCRIPT_URI =
  'https://lhawkinsandsonsltd.donateinmemory.net/assets/exports/embed.js'
const SCRIPT_ID = '__fds-script-tag'
const FDS_TENNANT = 'lhawkinsandsonsltd'
const ELEMENT_REF = '__fds-embed'

/**
 * Sets fds data attributes for iframe embed
 * Must be called before `embedScript`
 *
 * @param element - dom node to add data attributes to
 * @param fdsData - object with key value pairs for fds keys and values
 */
function setFdsData(element, fdsData) {
  Object.keys(fdsData).forEach((key) => {
    const prefix = 'data-fds-'
    const keyName = `${prefix}${key}`
    const value = fdsData[key]

    element.setAttribute(keyName, value)
  })
}

/**
 * adds a script to the page
 *
 * @param url - script url
 * @param id - id to be added to script tag
 */
function embedScript(url, id) {
  const element = document.createElement('script')

  element.setAttribute('src', url)
  element.setAttribute('id', id)

  document.head.appendChild(element)
}

/**
 * remove script tag by id
 *
 * @param id - script id
 */
function rmScript(id) {
  const element = document.getElementById(id)

  document.head.removeChild(element)
}

export default {
  name: 'Embed',

  props: {
    fdsData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      scriptURI: SCRIPT_URI,
      tennant: FDS_TENNANT,
      elementRef: ELEMENT_REF,
      observer: null
    }
  },

  mounted() {
    const target = this.$refs[ELEMENT_REF]
    const fdsData = this.fdsData

    // When donations thing adds an iframe to `target`, bind load event listener
    // This is so when the frame changes page, we scroll to the top
    this.observer = new MutationObserver((mutationList, observer) => {
      mutationList.forEach((mutation) => {
        if (mutation.type == 'childList') {
          const frame = mutation.addedNodes[0]

          frame.addEventListener('load', () => {
            window.scrollTo(0, 0)
          })

          observer.disconnect()
        }
      })
    })

    this.observer.observe(target, {childList: true})

    setFdsData(target, fdsData)
    embedScript(SCRIPT_URI, SCRIPT_ID)
  },

  destroyed() {
    rmScript(SCRIPT_ID)
  }
}
</script>

<style lang="sass" scoped>
.embed
</style>
