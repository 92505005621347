<template>
  <div class="post">
    <Banner :image-versions="featuredImageVersions">
      <h1>{{ title }}</h1>
      <div v-html="acf.sub_heading"></div>
    </Banner>

    <PageContainer>
      <div class="post-main" v-html="content"></div>

      <Sidebar
        :ctaTitle="ctaTitle"
        :packagesTitle="packagesTitle"
        :advice="advice"
        :services="services"
        :packages="packages"
      />
    </PageContainer>
  </div>
</template>

<script>
import post from '@/mixins/post'

import Banner from '@/components/Banner'
import Sidebar from '@/components/Sidebar'
import PageContainer from '@/components/PageContainer'

function defaultToArray(value) {
  return value || []
}

export default {
  name: 'Post',

  mixins: [post],

  props: {
    post: {
      type: Object,
      required: true
    }
  },

  components: {
    Banner,
    Sidebar,
    PageContainer
  },

  computed: {
    advice() {
      const advice = this.acf.related_advice
      return defaultToArray(advice)
    },

    services() {
      const services = this.acf.related_services
      return defaultToArray(services)
    },

    packages() {
      const packages = this.acf.related_packages
      return defaultToArray(packages)
    },

    ctaTitle() {
      const type = this.postType
      const name = this.acf.name_singular

      if (type === 'services') {
        return `Interested in arranging a ${name}?`
      }

      if (type === 'packages') {
        return `Interested in a ${name}?`
      }

      if (type === 'advice') {
        return `Want to talk about ${name}?`
      }

      return 'Need advice?'
    },

    packagesTitle() {
      return 'Related packages'
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.post
  .post-main

    img
      width: 100%
      max-width: 800px

    @include media($media-gt-big)
      flex: 1
      padding-right: $pad
</style>
